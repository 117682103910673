import {
	IonCol,
	IonLoading,
	IonRow,
	IonSpinner,
	IonToggle,
} from "@ionic/react";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
	dateTimeFormatter,
	digitChanger,
	numberWithCommas,
	weekChanger,
} from "helpers/helper";
import { useDispatch, useSelector } from "react-redux";
import { getUserBusiness } from "config/user";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
} from "chart.js";
import { Bar, getElementAtEvent, getDatasetAtEvent } from "react-chartjs-2";
import Business from "services/Business";
import Orders from "services/Orders";
import Accounts from "services/Accounts";
import Add from "assets/images/icon/svg/Add";
import Calendar from "assets/images/icon/svg/Calendar";
import { getSelectedDate } from "redux/container/dashboardSlice";
import { COUNTRYINFO } from "data/country";
import ProfitLossChart from "./ProfitLossChart";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export default function AccountDashboard() {
	const [profitLoss, setProfitLoss] = useState({});
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dateInfo = useSelector((state) => state.dashboard.dateInfo);
	const navigate = useNavigate();
	const { createUserData } = useSelector((state) => state.user);
	const [transaction, setTransaction] = useState([]);

	const [selectedDate, setSelectedDate] = useState(
		dateInfo ? JSON.stringify(dateInfo) : moment(new Date())
	);
	const [dueLoan, setDueLoan] = useState();
	const [startDate, setStartDate] = useState(moment().startOf("day")._d);
	const [endDate, setEndDate] = useState(null);
	const [selectLevel, setSelectLevel] = useState(
		dateInfo?.level ? dateInfo.level : "today"
	);
	const [loader, setLoader] = useState(false);

	const userBusiness = getUserBusiness();
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.auth.data);
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	// const [dayCalender, setDayCalender] = useState(false);
	// const [rangeCalender, setRangeCalender] = useState(false);
	const [dayCalender, setDayCalender] = useState(
		dateInfo?.level === "selectDay" ? true : false
	);
	const [rangeCalender, setRangeCalender] = useState(
		dateInfo?.level === "custom" ? true : false
	);

	const getTransaction = async () => {
		setLoader(true);
		let response = await Accounts.getDashboardTransactions(timeZone);
		if (response.success) {
			setLoader(false);
			setTransaction(
				response?.data.map(
					(elements) =>
						elements && {
							...elements,
							name: elements.month,
							value: elements?.total_profit_loss,
						}
				)
			);
		} else {
			setLoader(false);
		}
	};

	useEffect(() => {
		getTransaction();
	}, []);

	const getProfitLoss = async (start, end) => {
		setLoader(true);
		let response = await Accounts.getProfitLoss(start, end);
		if (response.success) {
			setLoader(false);
			setProfitLoss(response);
		} else {
			setLoader(false);
		}
	};
	moment.updateLocale("en", {
		week: {
			dow: 1,
		},
	});
	useEffect(() => {
		let interval = setTimeout(() => {
			let start = moment().startOf("day").toString();
			let end = moment().endOf("day").toString();
			let dateTime = { start, end, level: selectLevel };
			let stringDateTime = JSON.stringify(dateTime);

			setSelectedDate(stringDateTime);
			getProfitLoss(
				moment(start).format("YYYY-MM-DD"),
				moment(end).format("YYYY-MM-DD")
			);

			// const startDay = moment()
			// 	.startOf("week")
			// 	.day("Monday")
			// 	.format("YYYY-MM-DD");
			// const endDay = moment()
			// 	.endOf("week")
			// 	.day("Monday")
			// 	.format("YYYY-MM-DD");
			// setExpenseDataPeriod({
			// 	startDate: moment(startDay).format("YYYY-MM-DD"),
			// 	endDate: moment(endDay).format("YYYY-MM-DD"),
			// });
			// setRevenueDataPeriod({
			// 	startDate: moment(startDay).format("YYYY-MM-DD"),
			// 	endDate: moment(endDay).format("YYYY-MM-DD"),
			// });

			// getExpenseData(startDay, endDay);
			// getRevenueData(startDay, endDay);
			const startDay = moment().startOf("week").format("YYYY-MM-DD");
			const endDay = moment().endOf("week").format("YYYY-MM-DD");

			setExpenseDataPeriod({
				startDate: startDay,
				endDate: endDay,
			});

			setRevenueDataPeriod({
				startDate: startDay,
				endDate: endDay,
			});

			setDuePaymentDataPeriod({
				startDate: startDay,
				endDate: endDay,
			});

			// Fetch data using the formatted dates
			getExpenseData(startDay, endDay);
			getRevenueData(startDay, endDay);
			getDeuPaymentData(startDay, endDay);
		}, 700);

		return () => clearTimeout(interval);
	}, []);

	const [expenseData, setExpenseData] = useState([]);
	const [expenseLoader, setExpenseLoader] = useState(false);
	const [expenseDataPeriod, setExpenseDataPeriod] = useState({
		startDate: "",
		endDate: "",
	});
	const [revenueData, setRevenueData] = useState([]);
	const [revenueLoader, setRevenueLoader] = useState(false);
	const [revenueDataPeriod, setRevenueDataPeriod] = useState({
		startDate: "",
		endDate: "",
	});
	const [duePaymentData, setDuePaymentData] = useState([]);
	const [duePaymentLoader, setDuePaymentLoader] = useState(false);
	const [duePaymentDataPeriod, setDuePaymentDataPeriod] = useState({
		startDate: "",
		endDate: "",
	});

	const [profitLossLoader, setProfitLossLoader] = useState(false);

	const getExpenseData = async (start, end) => {
		let accountType = await Accounts.getAccountType("Expense");

		if (accountType.success) {
			setExpenseLoader(true);
			let response = await Accounts.getTransactionDate(
				accountType?.results?.id,
				start,
				end
			);
			if (response.status === 200) {
				setExpenseLoader(false);
				setExpenseData(response.results);
			} else {
				setExpenseLoader(false);
			}
		}
	};
	const getRevenueData = async (start, end) => {
		let accountType = await Accounts.getAccountType("Revenue");

		if (accountType.success) {
			setRevenueLoader(true);
			let response = await Accounts.getTransactionDate(
				accountType?.results?.id,
				start,
				end
			);
			if (response.status === 200) {
				setRevenueLoader(false);
				setRevenueData(response.results);
			} else {
				setRevenueLoader(false);
			}
		}
	};
	const getDeuPaymentData = async (start, end) => {
		let accountType = await Accounts.getAccountType("Expense");

		if (accountType.success) {
			setDuePaymentLoader(true);
			let response = await Accounts.getDeuPaymentDate(
				accountType?.results?.id,
				start,
				end
			);

			if (response.status === 200) {
				setDuePaymentLoader(false);
				setDuePaymentData(response.results);
			} else {
				setDuePaymentLoader(false);
			}
		}
	};

	const [weekCount, setWeekCount] = useState(1);
	const [customerWeekCount, setCustomerWeekCount] = useState(1);
	const [duePaymentWeekCount, setDuePaymentWeekCount] = useState(1);

	const getWeekRange = (weeksAgo) => {
		moment.updateLocale("en", {
			week: {
				dow: 0,
			},
		});
		const endDay = moment()
			.subtract(weeksAgo * 7, "days")
			.endOf("week");
		const startDay = moment(endDay).subtract(6, "days");
		const formattedStartDay = startDay.format("YYYY-MM-DD");
		const formattedEndDay = endDay.format("YYYY-MM-DD");

		return { startDay: formattedStartDay, endDay: formattedEndDay };
	};
	const previousWeekHandler = (type) => {
		if (type === "expense") {
			setWeekCount(weekCount + 1);
			let start = getWeekRange(weekCount).startDay;
			let end = getWeekRange(weekCount).endDay;
			setExpenseDataPeriod({
				startDate: moment(start).format("YYYY-MM-DD"),
				endDate: moment(end).format("YYYY-MM-DD"),
			});

			getExpenseData(start, end);
		} else if (type === "duePayment") {
			setDuePaymentWeekCount(duePaymentWeekCount + 1);
			let start = getWeekRange(duePaymentWeekCount).startDay;
			let end = getWeekRange(duePaymentWeekCount).endDay;
			setDuePaymentDataPeriod({
				startDate: moment(start).format("YYYY-MM-DD"),
				endDate: moment(end).format("YYYY-MM-DD"),
			});

			getDeuPaymentData(start, end);
		} else {
			setCustomerWeekCount(customerWeekCount + 1);
			let start = getWeekRange(customerWeekCount).startDay;
			let end = getWeekRange(customerWeekCount).endDay;
			setRevenueDataPeriod({
				startDate: moment(start).format("YYYY-MM-DD"),
				endDate: moment(end).format("YYYY-MM-DD"),
			});
			getRevenueData(start, end);
		}
	};

	const nextWeekHandler = (type) => {
		if (type === "expense") {
			if (weekCount > 1) {
				setWeekCount(weekCount - 1);
				let start = getWeekRange(weekCount - 2).startDay;
				let end = getWeekRange(weekCount - 2).endDay;
				setExpenseDataPeriod({
					startDate: moment(start).format("YYYY-MM-DD"),
					endDate: moment(end).format("YYYY-MM-DD"),
				});
				getExpenseData(start, end);
			}
		} else if (type === "duePayment") {
			setDuePaymentWeekCount(duePaymentWeekCount - 1);
			let start = getWeekRange(duePaymentWeekCount - 2).startDay;
			let end = getWeekRange(duePaymentWeekCount - 2).endDay;
			setDuePaymentDataPeriod({
				startDate: moment(start).format("YYYY-MM-DD"),
				endDate: moment(end).format("YYYY-MM-DD"),
			});
			getDeuPaymentData(start, end);
		} else {
			if (customerWeekCount > 1) {
				setCustomerWeekCount(customerWeekCount - 1);
				let start = getWeekRange(customerWeekCount - 2).startDay;
				let end = getWeekRange(customerWeekCount - 2).endDay;
				setRevenueDataPeriod({
					startDate: moment(start).format("YYYY-MM-DD"),
					endDate: moment(end).format("YYYY-MM-DD"),
				});
				getRevenueData(start, end);
			}
		}
	};

	const expenseChartData = {
		labels: expenseData?.map((item) => item?.name),
		datasets: [
			{
				data: expenseData?.map((item) => item?.amount),
				backgroundColor: "#FB766E",
			},
		],
	};

	const revenueChartData = {
		labels: revenueData?.map((item) => item?.name),
		datasets: [
			{
				data: revenueData?.map((item) => item?.amount),
				backgroundColor: "#00B224",
			},
		],
	};
	const duePaymentChartData = {
		labels: duePaymentData?.map((item) => item?.name),
		datasets: [
			{
				data: duePaymentData?.map((item) => item?.amount),
				backgroundColor: "#FB766E",
			},
		],
	};

	const profitLossChartData = {
		labels: transaction?.map((entry) => entry?.name),
		datasets: [
			{
				label: "Profit/Loss",
				data: transaction?.map((entry) => entry?.value),
				backgroundColor: transaction?.map((entry) =>
					entry.value >= 0 ? "#00B224" : "#FB766E"
				),
				tooltipLabel: transaction?.map((entry) =>
					entry.value >= 0 ? "Profit" : "Loss"
				), // Custom label for each bar in the tooltip
			},
		],
	};

	const options = [
		{ value: "today", label: ekkLanguage.dashboard.today },
		{ value: "yesterday", label: ekkLanguage.dashboard.yesterday },
		{ value: "week", label: ekkLanguage.dashboard.thisWeek },
		{ value: "month", label: ekkLanguage.dashboard.thisMonth },
		{ value: "year", label: ekkLanguage.dashboard.thisYear },
		{ value: "lastWeek", label: ekkLanguage.dashboard.lastWeek },
		{ value: "lastMonth", label: ekkLanguage.dashboard.lastMonth },
		{ value: "lastYear", label: ekkLanguage.dashboard.lastYear },
		{ value: "custom", label: ekkLanguage.dashboard.custom },
		{ value: "selectDay", label: ekkLanguage.dashboard.selectDay },
	];

	// const OfferStartDate = forwardRef(({ value, onClick }, ref) => (
	// 	<div
	// 		className={`bg-white h-10 w-10 rounded-[5px] ml-3 flex items-center justify-center`}
	// 		onClick={onClick}>
	// 		<Calendar></Calendar>
	// 	</div>
	// ));

	const selectDateChanger = (e) => {
		if (e.value === "custom") {
			setRangeCalender(true);
			setDayCalender(false);
			setSelectLevel(e.value ?? options[0]);
			let timeDate = dateTimeFormatter(e.value);
			let dateTime = {
				start: timeDate.start,
				end: timeDate.end,
				level: e.value,
			};
			let stringDateTime = JSON.stringify(dateTime);
			setSelectedDate(stringDateTime);
			dispatch(getSelectedDate(dateTime));
		} else if (e.value === "selectDay") {
			setDayCalender(true);
			setRangeCalender(false);
			setSelectLevel(e.value ?? options[0]);
			let timeDate = dateTimeFormatter(e.value);
			let dateTime = {
				start: timeDate.start,
				end: timeDate.end,
				level: e.value,
			};
			let stringDateTime = JSON.stringify(dateTime);
			setSelectedDate(stringDateTime);
			dispatch(getSelectedDate(dateTime));
		} else {
			setRangeCalender(false);
			setDayCalender(false);
			setSelectLevel(e.value ?? options[0]);
			let timeDate = dateTimeFormatter(e.value);
			let dateTime = {
				start: timeDate.start,
				end: timeDate.end,
				level: e.value,
			};
			dispatch(getSelectedDate(dateTime));
			let stringDateTime = JSON.stringify(dateTime);
			setSelectedDate(stringDateTime);
			// Get the analytics for the selected date range.

			getProfitLoss(
				moment(timeDate.start).format("YYYY-MM-DD"),
				moment(timeDate.end).format("YYYY-MM-DD")
			);
		}
	};

	const onDayChange = (dates) => {
		const end = moment(dates).endOf("day")._d;
		setStartDate(dates);
		setEndDate(endDate);
		let dateTime = { start: dates, end, level: selectLevel };
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
		getProfitLoss(
			moment(dates).format("YYYY-MM-DD"),
			moment(end).format("YYYY-MM-DD")
		);
	};

	const onRangeStart = (dates) => {
		setStartDate(dates);
		setEndDate(null);
		let dateTime = {
			start: dates,
			end: null,
			level: selectLevel,
		};
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
	};
	const onRangeEnd = (dates) => {
		const end = moment(dates).endOf("day")._d;
		setEndDate(end);
		let dateTime = {
			start: JSON.parse(selectedDate).start,
			end,
			level: selectLevel,
		};
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
		getProfitLoss(
			moment(JSON.parse(selectedDate).start).format("YYYY-MM-DD"),
			moment(end).format("YYYY-MM-DD")
		);
	};

	let getDetails = (name, listName) => {
		return navigate(`/dashboard-details/${selectedDate}/${name}/${listName}`);
	};

	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`w-full h-10 border p-2.5 rounded relative bg-white ml-4
								${
									selectedDate
										? "border-transparent font-bold text-[#333333]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{selectedDate ? (
						<Calendar color={"#333333"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{selectedDate ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#333333] pt-0.5 '>
							{moment(JSON.parse(selectedDate)?.start).format(
								"DD-MM-YYYY"
							)}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							Select Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{selectedDate ? (
						<Add color={"#333333"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));

	const FromDateInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`w-full h-10 border p-2.5 rounded relative bg-white mt-4
								${
									JSON.parse(selectedDate)?.start
										? "border-[#333333] font-bold text-[#333333]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{JSON.parse(selectedDate)?.start ? (
						<Calendar color={"#333333"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{JSON.parse(selectedDate)?.start ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#333333] pt-0.5 '>
							{moment(JSON.parse(selectedDate)?.start).format(
								"DD-MM-YYYY"
							)}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							From Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{JSON.parse(selectedDate)?.start ? (
						<Add color={"#333333"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));

	const ToDateInput = forwardRef(({ value, onClick }, ref) => (
		<div
			style={{ width: "calc(100%-12px" }}
			ref={ref}
			className={`w-full h-10 border p-2.5 rounded relative bg-white mt-4
								${
									JSON.parse(selectedDate)?.end
										? "border-[#333333] font-bold text-[#333333]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{JSON.parse(selectedDate)?.end ? (
						<Calendar color={"#333333"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{JSON.parse(selectedDate)?.end ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#333333] pt-0.5 '>
							{moment(JSON.parse(selectedDate)?.end).format(
								"DD-MM-YYYY"
							)}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							To Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{JSON.parse(selectedDate)?.end ? (
						<Add color={"#333333"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));

	//chart details

	moment.updateLocale("en", {
		week: {
			dow: 0,
		},
	});

	let [delayList, setDelayList] = useState([]);
	let [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);

	const getDelayList = async (type = "Expense", isPaid = "False") => {
		let accountType = await Accounts.getAccountType(type);
		setLoading(true);
		let response = await Accounts.getDelayList(
			accountType?.results?.id,
			isPaid
		);
		if (response.success) {
			setLoading(false);
			setDelayList(response?.results);
		} else {
			setDelayList([]);
			setLoading(false);
		}
	};
	useEffect(() => {
		getDelayList();
	}, []);

	const typeOptions = [
		// { value: "All", label: ekkLanguage.orderProductSummary.all },
		{ value: "Revenue", label: "Revenue" },
		{ value: "Expense", label: "Expense" },
	];
	const [selectedType, setSelectedType] = useState("");
	const [isPaid, setIsPaid] = useState(true);
	const selectStatusChanger = async (e) => {
		setSelectedType(e?.value);
		getDelayList(e?.value, isPaid ? "True" : "False");
		// let dateStatus = {
		// 	status: e.value,
		// 	date: selectedDate,
		// };
		// dispatch(getSelectedDate(dateStatus));
	};

	// const [isPaidText, setIsPaidText] = useState('Paid');
	const handleToggleChange = (e) => {
		const newValue = e.detail.checked;
		setIsPaid(newValue);
		getDelayList(selectedType, newValue ? "True" : "False");
		// setIsPaidText(
		// 	newValue
		// 		? ekkLanguage.accounting.paid
		// 		: ekkLanguage.accounting.notPaid
		// );
	};
	const revenueBarRef = useRef();
	const expenseBarRef = useRef();
	const duePaymentBarRef = useRef();
	const profitLossBarRef = useRef();

	const barClickHandler = (event, type) => {
		// expenseChartData;
		if (type === "revenue") {
			let date =
				revenueChartData?.labels[
					getElementAtEvent(revenueBarRef.current, event)[0]?.index
				];
			const startTime = moment(date, "DD-MM-YYYY")
				.startOf("day")
				.toISOString();
			const endOfTime = moment(date, "DD-MM-YYYY")
				.endOf("day")
				.toISOString();
			if (
				revenueChartData?.datasets[0].data[
					getElementAtEvent(revenueBarRef.current, event)[0]?.index
				] > 0
			) {
				navigate(`/revenue-parts?state=${startTime}&end=${endOfTime}`);
			}
		} else if (type === "expense") {
			let date =
				expenseChartData?.labels[
					getElementAtEvent(expenseBarRef.current, event)[0]?.index
				];
			const startTime = moment(date, "DD-MM-YYYY")
				.startOf("day")
				.toISOString();
			const endOfTime = moment(date, "DD-MM-YYYY")
				.endOf("day")
				.toISOString();

			if (
				expenseChartData?.datasets[0].data[
					getElementAtEvent(expenseBarRef.current, event)[0]?.index
				] > 0
			) {
				navigate(`/expense-parts?state=${startTime}&end=${endOfTime}`);
			}
		} else if (type === "duePayment") {
			let date =
				duePaymentChartData?.labels[
					getElementAtEvent(duePaymentBarRef.current, event)[0]?.index
				];
			const startTime = moment(date, "DD-MM-YYYY")
				.startOf("day")
				.toISOString();
			const endOfTime = moment(date, "DD-MM-YYYY")
				.endOf("day")
				.toISOString();

			if (
				duePaymentChartData?.datasets[0].data[
					getElementAtEvent(duePaymentBarRef.current, event)[0]?.index
				] > 0
			) {
				navigate(`/due-payment-parts?state=${startTime}&end=${endOfTime}`);
			}
		} else if (type === "profitLoss") {
			let date =
				profitLossChartData?.labels[
					getElementAtEvent(profitLossBarRef.current, event)[0]?.index
				];
			const startTime = moment(date, "MM-YYYY")
				.startOf("month")
				.toISOString();
			const endOfTime = moment(date, "MM-YYYY").endOf("month").toISOString();
			if (
				profitLossChartData?.datasets[0].data[
					getElementAtEvent(profitLossBarRef.current, event)[0]?.index
				] !== undefined
			) {
				navigate(`/profit-loss-parts?state=${startTime}&end=${endOfTime}`);
			}
		}
	};

	return (
		<div>
			<IonLoading
				isOpen={loader}
				message={ekkLanguage.createOrder.loader}
				spinner='circles'
			/>
			<div className='chart-wrapper relative mb-4'>
				{revenueLoader && (
					<div className='absolute h-full w-full flex justify-center items-center left-[7%] top-[5%]'>
						<IonSpinner name='circular'></IonSpinner>
					</div>
				)}

				<div className='px-2 pb-1 flex justify-between items-center pt-5'>
					<h4 className='font-bold text-20 text-black-1000'>Revenue</h4>
				</div>
				<div className='flex justify-between py-3 items-center'>
					<button
						className='font-bold h-9 rounded-[5px] ml-2 border-2 bg-success text-white px-2 text-12'
						onClick={() => previousWeekHandler("revenue")}>
						Pervious Week
					</button>
					<h4 className='font-bold'>
						<span className='font-semibold text-12'>
							{digitChanger(
								moment(revenueDataPeriod?.startDate).format(
									"DD-MM-YYYY"
								)
							)}{" "}
							-{" "}
							{digitChanger(
								moment(revenueDataPeriod?.endDate).format("DD-MM-YYYY")
							)}
						</span>
					</h4>
					<button
						className={`font-bold h-9 rounded-[5px] ml-2 border-2 ${
							customerWeekCount === 1 ? "bg-36A94D-333" : "bg-success"
						}  text-white px-2 text-12`}
						onClick={() => nextWeekHandler("revenue")}>
						Next Week
					</button>
				</div>

				<div
					className='flex justify-center px-2'
					style={{ width: "100%", maxWidth: "768px" }}>
					<Bar
						data={revenueChartData}
						options={{ barPercentage: 0.7 }}
						ref={revenueBarRef}
						onClick={(e) => barClickHandler(e, "revenue")}
					/>
				</div>
			</div>
			<div className='chart-wrapper relative'>
				{expenseLoader && (
					<div className='absolute h-full w-full flex justify-center items-center left-[7%] top-[5%]'>
						<IonSpinner name='circular'></IonSpinner>
					</div>
				)}

				<div className='px-2 pb-1 flex justify-between items-center pt-5'>
					<h4 className='font-bold text-20 text-black-1000'>Expense</h4>
				</div>
				<div className='flex justify-between py-3 items-center'>
					<button
						className='font-bold h-9 rounded-[5px] ml-2 border-2 bg-success text-white px-2 text-12'
						onClick={() => previousWeekHandler("expense")}>
						Pervious Week
					</button>
					<h4 className='font-bold'>
						<span className='font-semibold text-12'>
							{digitChanger(
								moment(expenseDataPeriod?.startDate).format(
									"DD-MM-YYYY"
								)
							)}{" "}
							-{" "}
							{digitChanger(
								moment(expenseDataPeriod?.endDate).format("DD-MM-YYYY")
							)}
						</span>
					</h4>
					<button
						className={`font-bold h-9 rounded-[5px] ml-2 border-2 ${
							weekCount === 1 ? "bg-36A94D-333" : "bg-success"
						}  text-white px-2 text-12`}
						onClick={() => nextWeekHandler("expense")}>
						Next Week
					</button>
				</div>

				<div
					className='flex justify-center px-2'
					style={{ width: "100%", maxWidth: "768px" }}>
					<Bar
						data={expenseChartData}
						options={{ barPercentage: 0.7 }}
						ref={expenseBarRef}
						onClick={(e) => barClickHandler(e, "expense")}
					/>
				</div>
			</div>

			{/* <div className='py-5'>
				<div>
					<div className='px-2 flex justify-between items-center pt-1 pb-5'>
						<h4 className='font-bold text-20 text-black-1000'>
							Profit & Loss
						</h4>
					</div>
				</div>
				<div className='px-2'>
					<div className={dayCalender ? "flex" : "block"}>
						<div className={dayCalender ? "w-[calc(50%-8px)]" : "w-full"}>
							<Select
								isSearchable={false}
								defaultValue={options.filter(
									(el) => el.value === dateInfo.level
								)}
								placeholder='Select date'
								options={options}
								onChange={selectDateChanger}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										// width: "100%",
										height: 40,
										// border: "none",
										outLine: "none",
										fontWeight: 600,
										fontSize: 12,
										borderRadius: 5,
									}),
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: 5,
									colors: {
										...theme.colors,
										primary25: "rgb(204, 255, 214)",
										primary: "#00FF33",
									},
								})}
							/>
						</div>
						{dayCalender && (
							<div className='w-[calc(50%-8px)]'>
								<DatePicker
									selected={startDate}
									onChange={onDayChange}
									startDate={startDate}
									endDate={endDate}
									selectsDisabledDaysInRange
									withPortal
									customInput={<CustomInput />}
								/>
							</div>
						)}
					</div>

					<div>
						{rangeCalender && (
							<div className='flex items-center'>
								<div className='mr-2 w-[calc(50%-8px)]'>
									<DatePicker
										selected={startDate}
										onChange={onRangeStart}
										startDate={startDate}
										endDate={startDate}
										selectsDisabledDaysInRange
										withPortal
										customInput={<FromDateInput />}
									/>
								</div>
								<div className='ml-2 w-[calc(50%-8px)]'>
									<DatePicker
										selected={endDate}
										onChange={onRangeEnd}
										startDate={endDate}
										endDate={endDate}
										selectsDisabledDaysInRange
										withPortal
										customInput={<ToDateInput />}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div> */}
			{/* {dateInfo?.start && dateInfo?.end ? (
				<>
					{" "}
					<h4 className='font-bold text-center'>
						<span className='font-semibold text-12'>
							{digitChanger(
								moment(dateInfo?.start).format("DD-MM-YYYY")
							)}{" "}
							-{" "}
							{digitChanger(moment(dateInfo?.end).format("DD-MM-YYYY"))}
						</span>
					</h4>
				</>
			) : undefined} */}

			{/* <div className='pt-4 mb-12'>
				<IonRow>
					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px] cursor-pointer'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								Profit
							</h4>
							<h2 className='font-bold text-16 text-center text-black-1000 pb-5'>
								{COUNTRYINFO?.[createUserData?.country]?.currency}
								{""}
								{digitChanger(
									numberWithCommas(profitLoss?.profit ?? 0)
								)}
							</h2>
							
						</div>
					</IonCol>
					<IonCol size='6' className='pl-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px] cursor-pointer'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								Loss
							</h4>
							<h2 className='font-bold text-16 text-center text-black-1000 pb-5'>
								{COUNTRYINFO?.[createUserData?.country]?.currency}
								{""}
								{digitChanger(numberWithCommas(profitLoss?.loss ?? 0))}
							</h2>
							
						</div>
					</IonCol>
				</IonRow>
			</div> */}

			{/* <div className='mb-4'>
				<div>
					<div style={{ width: "100%" }}>
						<ProfitLossChart
							transaction={transaction}
							barClickHandler={barClickHandler}
						/>
					</div>
				</div>
			</div> */}
			<div className='chart-wrapper relative mb-4'>
				{duePaymentLoader && (
					<div className='absolute h-full w-full flex justify-center items-center left-[7%] top-[5%]'>
						<IonSpinner name='circular'></IonSpinner>
					</div>
				)}

				<div className='px-2 pb-1 flex justify-between items-center pt-5'>
					<h4 className='font-bold text-20 text-black-1000'>
						{" "}
						Expense Payments Due
					</h4>
				</div>
				<div className='flex justify-between py-3 items-center'>
					<button
						className='font-bold h-9 rounded-[5px] ml-2 border-2 bg-success text-white px-2 text-12'
						onClick={() => previousWeekHandler("duePayment")}>
						Pervious Week
					</button>
					<h4 className='font-bold'>
						<span className='font-semibold text-12'>
							{digitChanger(
								moment(duePaymentDataPeriod?.startDate).format(
									"DD-MM-YYYY"
								)
							)}{" "}
							-{" "}
							{digitChanger(
								moment(duePaymentDataPeriod?.endDate).format(
									"DD-MM-YYYY"
								)
							)}
						</span>
					</h4>
					<button
						className={`font-bold h-9 rounded-[5px] ml-2 border-2 ${"bg-success"}  text-white px-2 text-12`}
						onClick={() => nextWeekHandler("duePayment")}>
						Next Week
					</button>
				</div>

				<div
					className='flex justify-center px-2'
					style={{ width: "100%", maxWidth: "768px" }}>
					<Bar
						data={duePaymentChartData}
						options={{ barPercentage: 0.7 }}
						ref={duePaymentBarRef}
						onClick={(e) => barClickHandler(e, "duePayment")}
					/>
				</div>
			</div>
			<div className='chart-wrapper relative mb-4'>
				{profitLossLoader && (
					<div className='absolute h-full w-full flex justify-center items-center left-[7%] top-[5%]'>
						<IonSpinner name='circular'></IonSpinner>
					</div>
				)}

				<div className='px-2 pb-1 flex justify-between items-center pt-5'>
					<h4 className='font-bold text-20 text-black-1000'>
						{" "}
						Profit & Loss
					</h4>
				</div>

				<div
					className='flex justify-center px-2'
					style={{ width: "100%", maxWidth: "768px" }}>
					<Bar
						data={profitLossChartData}
						options={{
							barPercentage: 0.7,
						}}
						ref={profitLossBarRef}
						onClick={(e) => barClickHandler(e, "profitLoss")}
					/>
				</div>
			</div>

			{/* <div className='pb-10'>
				<div>
					<div className='px-2 flex justify-between items-center pt-1 pb-5'>
						<h4 className='font-bold text-20 text-black-1000'>
							Expense Payments Due
						</h4>
					</div>
					<div></div>
					<div>
						<div className='flex justify-between items-center mb-6'>
							<div>
								<Select
									onFocus={(e) => (e.target.readOnly = true)}
									defaultValue={typeOptions[1]}
									options={typeOptions}
									onChange={selectStatusChanger}
									placeholder='Select Status'
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											width: "300px",
											height: 40,
											border: "1px solid #999",
											outLine: "none",
											fontWeight: 500,
											fontSize: 12,
											color: "blue",
											borderRadius: 5,
										}),
										placeholder: (baseStyles, state) => ({
											...baseStyles,
											// color: "#FF564C",
										}),
										singleValue: (baseStyles, state) => ({
											...baseStyles,
											// color: "#00B224",
											fontWeight: 700,
										}),
										indicatorSeparator: (baseStyles, state) => ({
											...baseStyles,
											display: "none",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											// color: status ? "#00B224" : "#FF564C",
											// color: "#999",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											backgroundColor: state.isSelected
												? "#f6f6f6"
												: "#fff", // Darker background for selected option
											color: state.isSelected ? "#000" : "#666", // Dark text for selected
											"&:hover": {
												backgroundColor: "#f2f2f2", // Hover effect
											},
										}),
									}}
									theme={(theme) => ({
										...theme,
										borderRadius: 5,
										colors: {
											...theme.colors,
											// primary25: "rgb(204, 255, 214)",
											// primary: "#00FF33",
											primary25: "#efefef",
											primary: "#f6f6f6",
										},
									})}
								/>
							</div>

							<div>
								<IonToggle
									labelPlacement='start'
									height='100'
									aria-label='Primary toggle'
									size='large'
									color='success'
									checked={isPaid}
									onIonChange={handleToggleChange}>
									<span className='font-bold text-14'>Paid</span>
								</IonToggle>
							</div>
						</div>

						<div className=''>
							<table
								className='table-auto w-full bg-white'
								style={{
									boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
								}}>
								<thead>
									<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
										<th className='border p-1 font-bold text-12'>
											S/N
										</th>
										
										<th className='border p-1 font-bold text-14'>
											Amount
										</th>
										<th className='border p-1 font-bold text-14'>
											Next Pay Date
										</th>
									</tr>
								</thead>
								<tbody>
									{delayList?.length
										? delayList?.map((el, i) => {
												return (
													<tr className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4 cursor-pointer'>
														<td className='border p-2 w-14 font-semibold text-10'>
															{digitChanger(i + 1)}
														</td>
														<td
															// onClick={() =>
															// 	handleNavigate(
															// 		e.productTitle,
															// 		e.catalogId
															// 	)
															// }
															className='border text-[#0099CC] font-semibold p-2 text-10'>
															{el?.amount}
														</td>
														<td className='border font-semibold p-2 text-10'>
															{el?.paid_date}
														</td>
													</tr>
												);
										  })
										: ""}
								</tbody>
							</table>
							{loading ? (
								<div className='flex items-center justify-center h-[156px] m-auto'>
									{" "}
									<IonSpinner name='crescent'></IonSpinner>
								</div>
							) : (
								""
							)}
							{delayList?.length === 0 && (
								<>
									<IonCol size='2'></IonCol>
									<IonCol size='8'>
										<div
											className='text-center flex items-center justify-center'
											style={{ height: "calc(100vh - 420px)" }}>
											<div>
												<img
													className='m-auto mb-[10px] mt-5'
													src='/assets/images/not-found-file.png'
													alt='emptyCard'
												/>
												<p className='text-16 font-normal text-222222 mb-[35px]'>
													{ekkLanguage.orderList.notFound}
												</p>
											</div>
										</div>
									</IonCol>
								</>
							)}
						</div>
					</div>
				</div>
			</div> */}
		</div>
	);
}
