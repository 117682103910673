const bangla = {
	login: {
		contryLabel: "দেশ",
		contryPlaceHolder: "",
		phoneLable: "ফোন নম্বর",
		phonePlaceHolder: "মোবাইল নম্বর লিখুন",
		validationLabel: "আপনার ১১ ডিজিটের ফোন নম্বরটি লিখুন।",
		btnLabel: "পরবর্তী",
		tokenSuccessLabel: " সফলভাবে টোকেন পাঠানো হয়েছে",
		tokenSuccessFailed:
			"টোকেনটি পাঠানো যায় নি, অনুগ্রহ করে আবার চেষ্টা করুন।",
		langChange: "Change language to",
		termsAllow: "লগ-ইন করার সময় শর্তাবলী অনুমতি দিন!",
	},
	password: {
		passwordLabel: "পাসওয়াার্ড",
		passwordPlaceHolder: "পাসওয়ার্ড লিখুন",
		forgetPass: "পাসওয়ার্ড ভুলে গিয়েছেন?",
		backWord: "পেছনে যান",
		btnLoadingLabel: "প্রবেশ হচ্ছে",
		btnLabel: "প্রবেশ করুন",
		otpSendLabel: "আপনার মোবাইলে কোড পাঠানো হয়েছে,",
		passError: "ফোন নম্বরের সাথে পাসওয়ার্ড মিলছে না",
	},
	changePass: {
		passwordLabel: "নতুন পাসওয়ার্ড",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "পাসওয়ার্ড নিশ্চিত করুন",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "পাসওয়ার্ড মেলে না",
		btnLabel: "পরবর্তী",
		successLabel: "আপনার পাসওয়ার্ড সফলভাবে পরিবর্তন হয়েছে",
		errorLabel: "আপনার পাসওয়ার্ড পরিবর্তন ব্যর্থ হয়েছে",
		lengthErrorLabel: "পাসওয়ার্ড কমপক্ষে ৪ অক্ষরের হতে হবে",
		notMatchLabel: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
	},
	token: {
		tokenLabel: "টোকেন",
		tokenPlaceHolder: "টোকেন লিখুন",
		tokenResend: "পুনরায় টোকেন পাঠাও",
		tokenSuccessLabel:
			" আপনার মোবাইলে কোড পাঠানো হয়েছে, পরবর্তী ধাপে যেতে এটি লিখুন",
		tokenErrorLabel: "টোকেনটি পাঠানো যায় নি, অনুগ্রহ করে আবার চেষ্টা করুন।",
		timerLabel: "অনুগ্রহ করে অপেক্ষা করুন ",
		second: "সেকেন্ড",
		btnLabel: "পরবর্তী",
		tokenNotMatch: "মেয়াদোত্তীর্ণ টোকেন",
		tokenVarified: "আপনার টোকেন যাচাই করা হয়েছে",
		tokenNotWrite: "আপনার টোকেন লিখুন",
	},
	registration: {
		nameLabel: "পূর্ণ নাম",
		namePlaceholder: "পূূর্ণ নাম লিখুন",
		emailLabel: "ইমেইল ",
		emailPlaceholder: "ইমেইল লিখুন",
		passwordLabel: "পাসওয়াার্ড",
		passwordPlaceholder: "পাসওয়ার্ড লিখুন",
		confirmPassLabel: "নিশ্চিত পাসওয়াার্ড",
		confirmPassPlaceholder: "নিশ্চিত পাসওয়ার্ড লিখুন",
		validationLabel: "ইনপুট ক্ষেত্র ৪ অক্ষরের হতে হবে",
		passNotMatch: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
		btnLabel: "পরবর্তী",
		successMessage: "প্রোফাইল সফলভাবে তৈরি করা হয়েছে",
		errorMessage: "বৈধ ইনপুট চেক করুন",
		passLengthValidLabel: "পাসওয়ার্ড কমপক্ষে ৪ অক্ষরের হতে হবে",
		passwordLength: "আপনার পাসওয়ার্ডটি",
		strong: "শক্তিশালী",
		medium: "মাধ্যম",
		weak: "দুর্বল",
		gender: "লিঙ্গ",
		male: "পুরুষ",
		female: "নারী",
		others: "অন্যান্য",
		dobLabel: "জন্ম তারিখ",
		day: "দিন",
		month: "মাস",
		year: "বছর",
	},
	letNav: {
		homeMenuLabel: "হোম",
		orderMenuLabel: "অর্ডার তালিকা",
		mySupplierMenuLabel: "আমার সাপ্লাইয়ার",
		businessProfileMenuLabel: "ব্যবসায়িক প্রোফাইল সেটিংস",
		phoneCall: "কলের মাধ্যমে অর্ডার",
	},
	menu: {
		accounting: "হিসাবরক্ষণ",
		procurement: "ক্রয়",
		products: "পণ্যসমূহ",
		loan: "ঋণ",
	},
	home: {
		showMoreLabel: "আরো দেখুন",
		brandLabel: "ব্র্যান্ড",
		offerLabel: "মূল্য ছাড় পণ্য",
		mySuppliersLabel: "আমার সাপ্লাইয়ার",
		nearSuppliersLabel: "কাছাকাছি সাপ্লাইয়ার",
		recommendentLabel: "শুধু আপনার জন্য",
		newProductLabel: "নতুন পণ্য",
		recommendentOrderLabel: "রিকমান্ডেড অর্ডার",
		latestNewsLabel: "সর্বশেষ সংবাদ",
		searchLabel: "আপনার পণ্য খুঁজুন।",
		supplierLabel: "আপনার সাপ্লাইয়ার খুঁজুন।",
		todayPurchaseLabel: "আজকের কেনাকাটা",
		totalOrderLabel: "ধরণের পণ্য কিনেছেন",
		totalOrder: "টি অর্ডারে",
		skusLabel: "টি অর্ডার",
		tillNow: "এখন পর্যন্ত",
		orderDue: "অর্ডার বাকি",
		brandProductPageTitle: "সকল ব্র্যান্ড",
		offerProductPageTitle: "মূল্য ছাড় পণ্য",
		offerProductNotFound: "দুঃখিত, আপনার জন্য কোন অফার পাওয়া যায়নি.",
		mySupplierNotFound:
			"আপনার কোনো সাপ্লাইয়ার নেই। এখানে আপনার সাপ্লাইয়ারদের যোগ করতে পণ্য কিনুন।",
		supplierNotFound:
			"দুঃখিত, আপনার এলাকায় কোন সাপ্লাইয়ার পাওয়া যায়নি, আমরা খুব শীঘ্রই আপনার এলাকায় কিছু সাপ্লাইয়ার বরাদ্দ করব।",
		brandSeller: "সাপ্লাইয়ার",
		allBrandSeller: "সকল সাপ্লাইয়ার",
		km: "কিমি",
		account: "অ্যাকাউন্ট",
		warning: `দয়া করে নোট করুন, লেনদেনগুলি ১লা মার্চ ২০২৪ প্রভাবিত হতে পারে, কারণ সিস্টেম আপগ্রেড করা হচ্ছে৷`,
		warningHeading: "সতর্কতা",
		product: "পণ্য",
		offerSearchNotFound: "এই নামে কোনো অফার খুঁজে পাওয়া যায়নি",
	},
	profile: {
		pageTitle: "প্রোফাইল এবং সেটিংস",
		contactMenuLable: "যোগাযোগ এবং মৌলিক তথ্য",
		passwordMenuLable: "পাসওয়ার্ড পরিবর্তন করুন",
		logoutMenuLable: "বেরিয়ে যান",
		changeLanguageMenuLable: "ভাষা পরিবর্তন করুন",
	},
	contact: {
		pageTitle: "যোগাযোগ এবং মৌলিক তথ্য",
		emailLabel: "ইমেইল",
		emailPlaceholder: "ইমেইল লিখুন",
		nidLabel: "এনআইডি",
		nidPlaceholder: "এনআইডি লিখুন",
		btnLabel: "সেভ করুন",
		successLabel: "আপনার প্রোফাইল সফলভাবে আপডেট করা হয়েছে.",
		currectEmail: "অনুগ্রহ করে সঠিক ইমেল ঠিকানা লিখুন",
	},
	otpsend: {
		pageTitle: "নম্বর যাচাই করুন",
		btnLabel: "পাঠান",
		message:
			"খুদেবার্তা এর মাধ্যমে আপনার মোবাইলে প্রমাণীকরণ কোড পাঠাতে ট্যাপ করুন।",
		successLabel: "আপনার ফোনে আপনার টোকেন পাঠানো হয়েছে",
		errorLabel: "আপনার টোকেন এখনো তৈরি হয়নি অনুগ্রহ করে আবার চেষ্টা করুন",
	},
	otpsubmit: {
		pageTitle: "নম্বর যাচাই করুন",
		btnLabel: "জমা দিন",
		title: "যাচাইকরণ কোড",
		verifiLabel: "যাচাই করুন এবং চালিয়ে যান",
		paragraph: "আমরা আপনার মোবাইল নম্বরে যাচাইকরণ কোড পাঠিয়েছি",
		otpLabel: "আপনার ওটিপি কোড লিখুন",
		otpPlaceholder: "আপনার ওটিপি কোড লিখুন",
		receiveYetLabel: "কোড পাননি?",
		againRequestLabel: "আবার অনুরোধ করুন",
		timerLabel: "অনুগ্রহ করে অপেক্ষা করুন ",
		second: "সেকেন্ড",
		successLabel: "আপনার ফোনে আপনার টোকেন পাঠানো হয়েছে",
		errorLabel: "আপনার টোকেন এখনো তৈরি হয়নি অনুগ্রহ করে আবার চেষ্টা করুন",
		tokenVarifiedMessage: "আপনার টোকেন যাচাই করা হয়েছে",
		validationLabel: "আপনার ওটিপি কোড জমা দিন",
	},
	changePassword: {
		pageTitle: "পাসওয়ার্ড পরিবর্তন",
		btnLabel: "জমা দিন",
		passwordLabel: "পাসওয়াার্ড",
		passwordPlaceholder: "পাসওয়ার্ড লিখুন",
		confirmPassLabel: "নিশ্চিত পাসওয়াার্ড",
		confirmPassPlaceholder: "নিশ্চিত পাসওয়ার্ড লিখুন",
		successLabel: "আপনার পাসওয়ার্ড আপডেট করা হয়েছে.",
		errorLabel: "আপনার পাসওয়ার্ড আপডেট ব্যর্থ হয়েছে",
		passNotMatchLabel: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
		changePassSuccessPara: "আপনার পাসওয়ার্ড সফলভাবে পরিবর্তন করা হয়েছে.",
		changePassContinueBtn: "পরবর্তী",
	},
	changeLanguages: {
		pageTitle: "ভাষা নির্বাচন করুন",
		btnLabel: "সংরক্ষণ করুন",
	},
	brandDetails: {
		skusLabel: "এসকেইউ",
		descriptionLabel: "বর্ণনা",
		productTitleLabel: "এই ব্র্যান্ডের সকল পণ্য",
	},
	mysuppliers: {
		skusLabel: "এসকেইউ",
		productTitleLabel: "এই সাপ্লায়ারের সকল পণ্য",
	},
	productList: {
		pageTitle: "মূল্য ছাড় পণ্য তালিকা",
	},
	productDetails: {
		offerStart: "অফার শুরুর তারিখ",
		offerEnd: "অফার শেষ হওয়ার তারিখ",
		moqLabel: "সর্বনিম্ন অর্ডার",
		consumerLabel: "ভোক্তা মূল্য (এমআরপি)",
		profitLabel: "লাভ",
		productDetailTitle: "পণ্যের বিবরণ",
		skuId: "এসকেইউ আইডি",
		expireData: "মেয়াদ উত্তীর্ণের তারিখ",
		manufactureData: "উত্পাদন তারিখ",
		productWeight: "পণ্যের ওজন",
		packingType: "প্যাকিং টাইপ",
		importedBy: "দ্বারা আমদানীকৃত",
		manufacturedBy: "দ্বারা নির্মিত",
		country: "দেশ",
		descriptionLabel: "বর্ণনা",
		btnLabel: "অর্ডার করুন",
		inStock: "ইন স্টক",
		outStock: "আউট স্টক",
		preOrderBtn: "প্রি-অর্ডার করুন",
		preOrderQuantity: "প্রি-অর্ডারের পরিমাণ লিখুন",
		searchTram: "অনুসন্ধানের ট্রাম",
	},
	createOrder: {
		pageTitle: "অর্ডার তৈরী করুন",
		buyerDetailsTitle: "ক্রেতার বিবরণ",
		sellerDetailsTitle: "বিক্রেতার বিবরণ",
		deliveryDetailsTitle: "পণ্য সরবরাহ",
		productItemTitle: "পণ্য আইটেম",
		costSummaryTitle: "খরচ সারসংক্ষেপ",
		paymentDetailsTitle: "অর্থ প্রদানের বিবরণ",
		paymentTermsTitle: "অর্থ প্রদানের শর্তাবলী",
		paymentMethodeTitle: "অর্থ প্রদানের পদ্ধতি",
		moqLabel: "সর্বনিম্ন অর্ডার",
		pcsLabel: "পিছ",
		subtotal: "মোট",
		tax: "ট্যাক্স / ভ্যাট",
		discount: "মূল্যছাড়",
		deliveryFee: "ডেলিভারি ফি",
		creditFee: "সার্ভিস চার্জ ",
		total: " মোট",
		requestDeliveryDate: "ডেলিভারি অনুরোধের তারিখ",
		immediatePayment: "তাৎক্ষণিক পেমেন্ট",
		pickAndPay: "পিক অ্যান্ড পে",
		today: "ডেলিভারি দিনের রাতে",
		threeDays: "৩ দিনের মধ্যে অর্থ প্রদান করুন",
		sevenDays: "৭ দিনের মধ্যে অর্থ প্রদান করুন",
		immediatePaymentText: "এই অর্ডারের জন্য কোনও সার্ভিস চার্জ  প্রযোজ্য নয়",
		todayPaymentText1: "আজকের পেমেন্টের জন্য, অতিরিক্ত সার্ভিস চার্জ  ",
		todayPaymentText2: "এই অর্ডারে যোগ করা হবে",
		threePaymentText1: "৩ দিনের পেমেন্টের জন্য, অতিরিক্ত সার্ভিস চার্জ ",
		threePaymentText2: "এই অর্ডারে যোগ করা হবে.",
		sevenPaymentText1: "৭ দিনের পেমেন্টের জন্য, অতিরিক্ত সার্ভিস চার্জ  ",
		sevenPaymentText2: "এই অর্ডারে যোগ করা হবে",
		cashOnDelivery: "ক্যাশ অন ডেলিভারি",
		cashOnDeliveryText1: "নগদ অর্থ প্রদান করুন",
		cashOnDeliveryText2: "যখন পণ্য বিতরণ করা হয়",
		cash: "ক্যাশ",
		bkash: "বিকাশ",
		nagad: "নগদ",
		cashText: "নগদ অর্থ প্রদান করুন",
		bkashText: "বিকাশের মাধ্যমে অর্থ প্রদান করুন",
		nagadText: "নগদের মাধ্যমে অর্থ প্রদান করুন",
		bankTransfer: "ব্যাংক ট্রান্সফার",
		bankTransferText1: "ব্যাংক ট্রান্সফার - পরিশোধ করুন",
		bankTransferText2: "রেফারেন্স হিসাবে 'অর্ডার নম্বর' সহ ব্যাংক অ্যাকাউন্ট",
		bankTransferText3: "ব্যাংকের নাম",
		bankTransferText4: "অ্যাকাউন্টের নাম",
		bankTransferText5: "অ্যাকাউন্টের নম্বর",
		bankTransferText6: "রাউট নম্বর",
		bankTransferText7: "শাখা",
		btnLabel: "নিশ্চিত",
		addMoreProductLabel: "আরও পণ্য যোগ করুন",
		selectProductLabel: "জমা দিন",
		cosgressTitle: "অভিনন্দন!",
		cosgressPara: "আপনার অর্ডার সম্পূর্ণ হয়েছে।",
		preOrderCongress:
			"আপনার প্রি-অর্ডার সম্পূর্ণ হয়েছে। শীগ্রই আপনার সাথে আমাদের প্রতিনিধি যোগাযোগ করবেন।",
		congressCancelText:
			"অর্ডার নিয়ে কোনো সমস্যা হলে রাত ১২ টার আগে বাতিল করুন। আপনি ১২ টার পরে অর্ডার বাতিল করতে পারবেন না।",
		congressProductLabel: "পরবর্তী",
		orderNow: "অর্ডার করুন",
		alert: "সতর্কতা",
		importentMessage: "গুরুত্বপূর্ণ বার্তা",
		deliveryDateMessage: "অনুগ্রহ করে ডেলিভারির তারিখ নির্বাচন করুন",
		paymentTermsMessage: "অনুগ্রহ করে অর্থপ্রদানের মেয়াদ নির্বাচন করুন",
		paymentMethodMessage: "অনুগ্রহ করে পেমেন্ট পদ্ধতি নির্বাচন করুন",
		backButtonMessage:
			"আপনার কার্টে কিছু আইটেম আছে, আপনি যদি এই পৃষ্ঠাটি ছেড়ে যান তবে আপনার কার্ট খালি হয়ে যাবে।",
		okBtnLabel: "ঠিক আছে",
		loader: "অনুগ্রহপূর্বক অপেক্ষা করুন...",
		placeConformationMessage: "আপনি কি এই অর্ডার দেওয়ার বিষয়ে নিশ্চিত?",
		lessThenMoq: "ইনপুট করা আইটেম পরিমাণ সর্বনিম্ন অর্ডার পরিমাণের চেয়ে কম।",
		all: "সবগুলো",
		number: "নম্বর",
		numberWarning:
			"এই নাম্বার ছাড়া অন্য কোন নাম্বারে টাকা পাঠালে সেটি বাতিল বলে গণ্য হবে",
		islamiBank: "ইসলামী ব্যাংক",
		bankAsia: "ব্যাংক এশিয়া",
		payTo: "প্রদান করুন",
		merchantNumber: "মার্চেন্ট নাম্বার",
		outOffStock: "পণ্যটি ষ্টকে নেই",
	},
	selectProduct: {
		pageTitle: "পণ্য নির্বাচন করুন",
		totalLabel: "মোট",
		btnLabel: "জমা দিন",
	},
	orderList: {
		pageTitle: "অর্ডার তালিকা",
		tabMenuRecent: "সবগুলো",
		tabMenuPlaced: "স্থাপিত",
		tabMenuAccepted: "গ্রহণ",
		tabMenuScDelivered: "ডেলিভারির জন্য নির্ধারিত",
		tabMenuPartialDelivered: "আংশিক ডেলিভারি",
		tabMenuDelivered: "ফুল ডেলিভারি",
		tabMenuReturn: "ফেরত পণ্য",
		tabMenuPartialPaid: "আংশিক পরিশোধ",
		tabMenuPaid: "ফুল পরিশোধ",
		tabMenuCancel: "বাতিল",
		tabMenuDeliveryFailed: "ডেলিভারি ব্যর্থ হয়েছে",
		orderItem: "অর্ডারকৃত পণ্য",
		seller: "বিক্রেতা",
		buyer: "ক্রেতা",
		createdBy: "তৈরি করেছেন",
		loader: "অনুগ্রহপূর্বক অপেক্ষা করুন...",
		notFound: "কোন তালিকা পাওয়া যায়নি।",
	},
	orderDetails: {
		createdBy: "তৈরি করেছেন",
		placedLabel: "অর্ডার হয়েছে ",
		deliveredLabel: "ডেলিভারী সম্পন্ন হয়েছে",
		paidLabel: "পরিশোধিত",
		orderItemLabel: "অর্ডারকৃত পণ্য",
		castItemLabel: "খরচের বিবরণ",
		timelineLabel: "সময়রেখা",
		subtotal: "মোট",
		tax: "ট্যাক্স / ভ্যাট",
		discount: "ডিসকাউন্ট",
		deliveryFee: "ডেলিভারি ফি",
		creditFee: "সার্ভিস চার্জ ",
		total: "মোট",
		paymentSettlementLabel: "অর্থ প্রদানের নিষ্পত্তি",
		date: "তারিখ",
		amount: "অর্থের পরিমাণ",
		dueDate: "অর্থ সংগৃহের তারিখ",
		remark: "মন্তব্য",
		collectedBy: "সংগৃহ করেছেন",
		paymentDetailsLabel: "অর্থ প্রদানের বিবরণ",
		paymentMethod: "অর্থ প্রদানের পদ্ধতি",
		paymentTerms: "অর্থ প্রদানের শর্তাবলী",
		paymentDueDate: "বাকি অর্থ প্রদানের তারিখ",
		totalDue: "বাকি",
		totalPaid: "পরিশোধ",
		remaining: "মোট বাকি",
		deliveryDetailsLabel: "ডেলিভারির বিস্তারিত",
		BuyerName: "ক্রেতার নাম",
		address: "ঠিকানা",
		phoneNumber: "মোবাইল নম্বর",
		email: "ইমেইল ",
		requestDeliveryDate: "ডেলিভারি অনুরোধের তারিখ",
		scheduleDeliveryDate: "সিডিউল ডেলিভারি তারিখ",
		scheduleDeliveryTime: "সিডিউল ডেলিভারি সময়",
		deliveryDate: "ডেলিভারি প্রদানের তারিখ",
		downloadInvoices: "চালান ডাউনলোড করুন",
		receiptDownloads: "রসিদ প্রিন্ট করুন",
		cancelOrder: "অর্ডার বাতিল করুন",
		cancelConformationAsk: "আপনি কি নিশ্চিত আপনি এই অর্ডার বাতিল করতে চান?",
		cancelConformationParagraph:
			"আপনি এই অর্ডারটি বাতিল করতে পারবেন না, আপনি শুধুমাত্র একই দিনে আপনার অর্ডার বাতিল করতে পারবেন।",
		yesBtn: "হ্যাঁ",
		noBtn: "না",
		withoutSettelment: "পেমেন্ট সম্পূর্ণ তথ্য এখানে দেখানো হবে।",
		bankInfoLabel: "পেমেন্ট ট্রান্সফারের জন্য ব্যাঙ্ক অ্যাকাউন্টের তথ্য",
		deliveryFailMessage: "ডেলিভারি ফেল মেসেজ",
		orderSearchLabel: "আপনার অর্ডারটি খুঁজুন",
		close: "বন্ধ করুন",
	},
	businessCreateName: {
		pageTitle: "ব্যবসার নাম",
		businessName: "ব্যবসার নাম",
		businessNamePlaceholder: "ব্যবসার নাম দিন",
		paragraph:
			"আপনার ব্যবসা তৈরি করতে ৩ ধাপ দূরে আছি আমরা। আপনার ব্যবসার নাম দিয়ে শুরু করা যাক।",
		btnLabel: "পরবর্তী",
		submit: "জমা দিন",
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		errorLabel: "ব্যবসায়ের নাম লিখুন!",
		congratulation: "অভিনন্দন!",
		congratulationMessage: "আপনার ব্যবসা সফলভাবে তৈরি হয়েছে",
		kycSubmit: "কেওয়াইসি সাবমিট করুন",
		pending:
			"কেওয়াইসি সাবমিট করার জন্য ধন্যবাদ। আপনার কেওয়াইসি ভেরিফিকেশন চলছে, অনুগ্রহ করে অপেক্ষা করুন।",
		goBack: "ফিরে যাও",
	},
	businessType: {
		pageTitle: "ব্যবসার ধরণ",
		paragraph: "আপনার ব্যবসার ধরণ নির্বাচন করুন",
		fmcg: "এফএমসিজি",
		miniMart: "মিনি মার্ট",
		superMart: "সুপার মার্ট",
		pharmacy: "ফার্মেসি",
		cart: "কার্ট",
		bazaarStore: "বাজার দোকান",
		hawkerCenter: "হকার সেন্টার",
		restaurant: "রেস্টুরেন্ট",
		hotel: "হোটেল",
		cosmeticsStore: "প্রসাধনী দোকান",
		nonFmcg: "নন এফএমসিজি",
		office: "অফিস",
		NGO: "এনজিও",
		entertainmentPlace: "বিনোদনের স্থান",
		religiousOrganization: "ধর্মীয় সংগঠন",
		spa: "স্পা",
		salon: "সেলুন",
		gym: "জিম",
		electronicsStore: "ইলেক্ট্রনিক্স স্টোর",
		shoeStore: "জুতার দোকান",
		fashionStore: "ফ্যাশন স্টোর",
		laundryStore: "লন্ড্রি স্টোর",
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		btnLabel: "পরবর্তী",
		validationLabel: "একটি ব্যবসার ধরন নির্বাচন করুন",
	},
	businessLocation: {
		pageTitle: "ব্যবসার অবস্থান",
		paragraph: "আপনার ব্যবসার ঠিকানা বিস্তারিত লিখুন",
		address: "ঠিকানা",
		addressPlaceholder: "রোড নম্বর/এলাকার নাম লিখুন",
		city: "শহর",
		cityPlaceholder: "শহরের নাম লিখুন",
		postalCode: "পোস্টাল কোড",
		postalCodePlaceholder: "পোস্টাল কোড লিখুন",
		email: "ইমেইল",
		emailPlaceholder: "আপনার ইমেল লিখুন",
		userName: "ব্যবহারকারীর নাম",
		userNamePlaceholder: "ব্যবহারকারীর নাম লিখুন",
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		btnLabel: "সম্পন্ন করুন",
		successLabel: "ব্যবসা তৈরি করার জন্য অভিনন্দন",
		errorLabel: "ব্যবসা তৈরি ব্যর্থ হয়েছে",
		validationLabel: "অনুগ্রহ করে ঠিকানাটি পূরণ করুন",
		loader: "দয়া করে অপেক্ষা করুন...",
		validationCityLabel: "অনুগ্রহ করে শহর পূরণ করুন.",
		validationPostCodeLabel: "অনুগ্রহ করে পোস্টাল কোড পূরণ করুন.",
	},
	locationPicker: {
		pageTitle: "ব্যবসার অবস্থান",
		btnLabel: "নিশ্চিত করুন",
		paragraph: `মেপ থেকে আপনার ব্যবসার অবস্থান নির্বাচন করুন।`,
	},
	location: {
		title: "আপনার অবস্থান সক্রিয় করুন",
		paragraph:
			"এই অ্যাপটির জন্য আপনার ডিভাইস এবং এই অ্যাপের জন্য লোকেশন পরিষেবা চালু থাকা প্রয়োজন। এই অ্যাপটি ব্যবহার করার আগে আপনাকে অবশ্যই সেগুলিকে সক্রিয় করতে হবে।",
		btnLabel: "শুধুমাত্র অ্যাপ ব্যবহার করার সময় অনুমতি দিন",
	},
	businessSelect: {
		pageTitle: "ব্যবসা নির্বাচন করুন",
	},
	pwaDownload: {
		header: "EkkBaz অ্যাপ ডাউনলোড করুন",
		paragraph:
			"অ্যাপটি ডাউনলোড করুন এবং এখন থেকে যেকোনো সময় আপনার সাপ্লাইয়ারর কাছ থেকে বাল্ক অর্ডার করুন।",
		cancelBtn: "বাতিল করুন",
		downloadBtn: "ডাউনলোড করুন",
	},
	businessProfile: {
		pageTitle: "ব্যবসায়িক প্রোফাইল সেটিংস",
		businessDetailsLabel: "বিস্তারিত",
		registrationNo: "নিবন্ধনের নম্বর",
		taxNo: "টেক্স/ভ্যাট নম্বর",
		mobileNo: "মোবাইল নম্বর",
		officeNo: "অফিস নম্বর",
		email: "ইমেইল",
		website: "ওয়েবসাইট",
		branchLabel: "শাখা তালিকা",
		paymentDetailsLabel: "পেমেন্ট বিবরণ",
		bankInfoLabel: "ব্যাংক তথ্য",
		bankName: "ব্যাংকের নাম",
		branchName: "ব্যাংকের শাখা",
		branchAddress: "ব্যাংকের ঠিকানা",
		swiftCode: "ব্যাংক সুইফট কোড",
		acName: "ব্যাঙ্কের A/C নাম",
		acNumber: "ব্যাংক এ/সি নম্বর",
		bkashInfoLabel: "বিকাশ তথ্য",
		bkashAcName: "বিকাশ এ/সি নাম",
		bkashAcNo: "বিকাশ এ/সি নম্বর",
		nagadInfoLabel: "নগদ তথ্য",
		nagadAcName: "নাগদ এ/সি নাম",
		nagadAcNo: "নাগদ এ/সি নম্বর",
		upayInfoLabel: "উপাই তথ্য",
		upayAcName: "উপাই এ/সি নাম",
		upayAcNo: "উপাই এ/সি নম্বর",
		verified: "যাচাই",
		unVerified: "যাচাই করা হয়নি",
		editBusinessMenu: "ব্যবসায়িক তথ্য পরিবর্তন করুন",
		addPayMenu: "পেমেন্ট অপশন যোগ করুন ",
		setLocation: "ব্যবসার লোকেশন আপডেট করুন",
		addMenu: "শাখা যোগ করুন",
		verifyBusinessMenu: "KYC তথ্য দিন",
		bankInfoNotFound: "ব্যাংক তথ্য পাওয়া যায়নি",
		deleteBusiness: "ব্যবসা মুছে ফেলুন",
		deleteWarning:
			"আপনার সমস্ত অর্ডার, পণ্য, কর্মচারী এবং আপনার সম্পূর্ণ অ্যাকাউন্ট মুছে ফেলা হবে। আপনি কি এই ব্যবসা মুছে ফেলার বিষয়ে নিশ্চিত?",
		otpSubmit: "ওটিপি প্রদান",
		confirmDeleteWarning:
			"আবার চিন্তা করুন! আপনার সমস্ত অর্ডার, পণ্য, কর্মচারী এবং আপনার সম্পূর্ণ অ্যাকাউন্ট মুছে ফেলা হবে। আপনি কি এই ব্যবসা মুছে ফেলার বিষয়ে নিশ্চিত?",
		switchBusiness: "ব্যবসা পরিবর্তন করুন",
		myEmployeesMenuLabel: "কর্মী তালিকা",
	},
	businessEdit: {
		pageTitle: "ব্যবসা সম্পাদনা করুন",
		uploadPhoto: "ছবি আপলোড",
		businessName: "ব্যাবসার নাম",
		businessNamePlaceholder: "ব্যাবসার নাম লিখুন",
		email: "ইমেইল",
		emailPlaceholder: "ইমেইল লিখুন",
		address: "ঠিকানা",
		addressPlaceholder: "আপনার ঠিকানা লিখুন",
		city: "শহর",
		cityPlaceholder: "শহরের নাম লিখুন",
		postalCode: "পোস্টাল কোড",
		postalCodePlaceholder: "পোস্টাল কোড লিখুন",
		resigter: "নিবন্ধনের নম্বর",
		resigterPlaceholder: "নিবন্ধনের নম্বর লিখুন",
		tex: "টেক্স/ভ্যাট নম্বর",
		texPlaceholder: "টেক্স/ভ্যাট নম্বর লিখুন",
		mobile: "মোবাইল নম্বর",
		mobilePlaceholder: "মুঠোফোন নম্বর লিখুন",
		office: "অফিস নম্বর",
		officePlaceholder: "দপ্তর নম্বর লিখুন",
		website: "ওয়েবসাইট",
		websitePlaceholder: "ওয়েবসাইট লিখুন",
		btnLabel: "সেভ করুন",
		successLabel: "আপনার প্রোফাইল সফলভাবে আপডেট করা হয়েছে.",
		currectEmail: "অনুগ্রহ করে সঠিক ইমেল ঠিকানা লিখুন",
	},
	bankDetails: {
		referenceNumber: "পরিচিত সংখ্যা",
		bankName: "ব্যাংকের নাম",
		accountName: "A/C নাম",
		accountNumber: "A/C নম্বর",
		routeNumber: "রুট নম্বর",
		branch: "শাখা",
	},
	invoice: {
		title: "চালান",
		receipt: "রসিদ",
		buyerDetails: "ক্রেতার বিবরণ",
		sellerDetails: "বিক্রেতার বিবরণ",
		description: "বর্ণনা",
		qty: "পরিমাণ",
		price: "মূল্য",
		subtotal: "মোট",
	},
	verifyBusiness: {
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		numberFour: "৪",
		numberFive: "৫",
		numberSix: "৬",
	},
	receipt: {
		placed: "স্থাপিত",
		name: "নাম",
		qty: "পরিমাণ",
		price: "মূল্য",
		subtotal: "সাবটোটাল",
		tax: "ট্যাক্স",
		discount: "ছাড়",
		creditFee: "সার্ভিস চার্জ ",
		deliveryFee: "ডেলিভারি করার টাকা",
		total: "মোট",
		paid: "পরিশোধিত",
		totalDue: "মোট বাকি",
		paymentTerms: "পরিশোধের শর্ত",
		days: "দিন",
		paymentMethod: "মূল্যপরিশোধ পদ্ধতি",
		referenceNumber: "ব্যাঙ্ক রেফারেন্স নম্বর",
		deliveryDate: "ডেলিভারির তারিখ ",
		dueDate: "পরিশোধযোগ্য তারিখ",
		printTime: "মুদ্রণের সময়",
		conditionsCreditFee: "অর্থপ্রদানের শর্তাবলী এবং সার্ভিস চার্জ ",
		immediatePayment: "জরুরী টাকা প্রদান",
		cashOnDelivery: "প্রদানোত্তর পরিশোধ",
		atNight: "ডেলিভারি দিবসের রাতে",
		payInthreeDay: "3 দিনের মধ্যে পেমেন্ট করুন",
		payInSevenDay: "7 দিনের মধ্যে পেমেন্ট করুন",
		afterSeven: "৭ দিন পর (প্রতিদিনের জন্য অতিরিক্ত)",
	},
	landing: {
		header1: "পাইকারি বাজার এখন হাতের মুঠোয়",
		header2: "একবাজ অ্যাপে অর্ডার করুন",
		header3: "পণ্য বুঝে পেমেন্ট করুন",
		login: "প্রবেশ করুন",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malaysia: "Malay",
		retail: "দোকানদার",
		manufacture: "সাপ্লায়ার",
		hotline: "হট লাইন",
		phoneNumber: "ফোন নম্বর",
		phoneNumberPlaceHolder: "ফোন নম্বর লিখুন",
		fAbout:
			"পাইকারী বাজার এখন হাতের মুঠোয়। একবাজ অ্যাপে অর্ডার করুন, পণ্য বুঝে পেমেন্ট করুন।",
		location: "অবস্থান",
		singapore: "সিঙ্গাপুর অফিস",
		bangladesh: "বাংলাদেশ অফিস",
		bOfficeAddress: "১৩ তলা, রাজ্জাক প্লাজা, মগ বাজার মোড়, ঢাকা-১২০৫",
		otherInfo: "অন্যান্য তথ্য",
		partners: "আমাদের অংশীদার",
		news: "খবর",
		support: "সাপোর্ট",
		corporate: "কর্পোরেট",
		refundPolicy: "রিফান্ড পলিসি",
		termsCondition: "টার্মস & কন্ডিশনস",
		privacyPolicy: "প্রাইভেসী পলিসি",
		contactUs: "যোগাযোগ করুন",
		messageUs: "মেসেজ করুন",
		facebook: "ফেসবুক",
		sales: "বিক্রয়",
		media: "মিডিয়া",
		downloadBtn: "ডাউনলোড করুন",
		backers: "আমাদের ব্যাকার্স",
		benefits: "আপনার সুবিধা",
		discover: "সর্বশেষ একবাজ খবর",
		benefitsOne: "২৪ ঘন্টা অন-টাইম ডেলিভারি",
		benefitsDisOne: "",
		benefitsTwo: "সেরা বাজার মূল্য",
		benefitsDisOneTwo: "",
		benefitsThree: "বৃহত্তম SKU ভাণ্ডার",
		benefitsDisOneThree: "",
		benefitsFore: "ক্রয়ের জন্য অর্থায়ন সমর্থন",
		benefitsDisOneFore: "",
		seeMore: "আরো দেখুন",
		weAvailable: "আমরা আছি",
		downloadApp: "অ্যাপ ডাউনলোড করুন",
	},
	seeMoreProduct: {
		brandsSearchPlaceholder: "ব্র্যান্ড সার্চ করুন",
		supplierSearchPlaceholder: "সাপ্লায়ার সার্চ করুন",
		offerSearchPlaceholder: "মূল্য ছাড় পণ্য সার্চ করুন",
	},
	sellerList: {
		pageTitle: "সাপ্লাইয়ার তালিকা",
		nearBySeller: "কাছাকাছি সাপ্লাইয়ার",
		allSeller: "সব সাপ্লাইয়ার",
		supplierNotFound: "আপনার কোনো সাপ্লাইয়ার নেই।",
		nearBySupplierNotFound:
			"দুঃখিত, আপনার এলাকায় কোন সাপ্লাইয়ার পাওয়া যায়নি, আমরা খুব শীঘ্রই আপনার এলাকায় কিছু সাপ্লাইয়ার বরাদ্দ করব।",
	},
	learning: {
		pageTitle: "শিক্ষণীয় বিষয়",
		learningDetailsTitle: "জ্ঞানের বিবরণ",
		comments: "মন্তব্য",
		commentAs: "এখানে আপনার মন্তব্য লিখুন",
		likedWarning: "একবারের বেশি লাইক দিতে পারবেন না",
	},
	navFooter: {
		home: "হোম",
		order: "অর্ডার",
		supplier: "সাপ্লাইয়ার",
		learning: "শিক্ষা",
		account: "অ্যাকাউন্ট",
	},
	notification: {
		pageTitle: "বিজ্ঞপ্তি",
		order: "অর্ডার",
		product: "প্রোডাক্ট",
		update: "আপডেট",
	},
	autoLogOut: {
		pageTitle: "স্বয়ংক্রিয় লগআউট",
		warningMsg:
			"স্বয়ংক্রিয় লগআউট যেহেতু এই নম্বরটি অন্য লগইনে ব্যবহৃত হয়েছিল৷",
		instrMsg: "আবার লগইন করতে নিচের সবুজ বোতামে ক্লিক করুন",
		btnLabel: "লগইন",
	},
	dashboard: {
		statusLable: "অর্ডারের অবস্থা",
		today: "আজ",
		yesterday: "গতকাল",
		thisWeek: "এই সপ্তাহ",
		thisMonth: "এই মাস",
		thisYear: "এই বছর",
		lastWeek: "গত সপ্তাহে",
		lastMonth: "গত মাসে",
		lastYear: "গত বছর",
		custom: "কাস্টম",
		selectDay: "নির্দিষ্ট দিন",
		Order: "অর্ডার",
		totalOrder: "মোট অর্ডার",
		placed: "স্থাপিত",
		accepted: "গ্রহণ",
		scDelivered: "ডেলিভারির জন্য নির্ধা..",
		partialDelivered: "আংশিক ডেলিভারি",
		delivered: "ফুল ডেলিভারি",
		return: "ফেরত পণ্য",
		partialPaid: "আংশিক পরিশোধ",
		paid: "ফুল পরিশোধ",
		totalPaid: "মোট পরিশোধ",
		cancel: "বাতিল",
		deliveryFailed: "ডেলিভারি ব্যর্থ হয়েছে",
		orderAmount: "অর্ডার এমাউন্ট",
		totalAmount: "মোট এমাউন্ট",
		collectedAmount: "কলেক্টেড এমাউন্ট",
		dueAmount: "বাকি",
		totalDue: "মোট বাকি",
		directOrder: "ডাইরেক্ট অর্ডার",
		directOrderAmount: "ডাইরেক্ট অর্ডার এমাউন্ট",
		ekkHeroOrder: "EkkHero অর্ডার",
		ekkHeroOrderAmount: "EkkHero অর্ডার এমা..",
		cancelOrderAmount: "বাতিল অর্ডার এমাউন্ট",
		seeDetails: "বিস্তারিত দেখুন",
	},
	voiceOrder: {
		pageTitle: "ভয়েস অর্ডারিং",
		bannerHello: "হ্যালো",
		bannerAI: "ভয়েস অর্ডারিং এআই-তে স্বাগতম",
		bannerUse: "আমাকে ব্যবহার করে আপনি প্রত্যাশিত পণ্য খুজে পেতে পারেন",
		speechTitle: "আপনার উক্তি",
		speechHeader: "আমি অর্ডার করতে চাই - ",
		speechInfo: "পন্য খুজে পেতে উপরের সাদা বাটনে ক্লিক করে রেকর্ড করুন",
		keywordTitle: "মেলানো কীওয়ার্ড",
		keywordInfo: "রিলেটেড পন্য পেতে উপরের একটি কীওয়ার্ড সিলেক্ট করুন",
		productsTitle: "মেলানো পণ্যের তালিকা",
		notFound:
			"দুঃখিত, আমি কোনো পণ্য খুঁজে পাইনি, অনুগ্রহ করে আবার চেষ্টা করুন..!",
	},
	addBankSettings: {
		bottomSheetTitle: "ব্যাংক অ্যাকাউন্ট যোগ করুন",
		pageTitle: "ব্যাংক অ্যাকাউন্ট যোগ করুন",
		accountName: "অ্যাকাউন্ট নাম",
		accountNamePlaceholder: "আপনার অ্যাকাউন্ট নাম লিখুন",
		accountNumber: "অ্যাকাউন্ট নম্বর",
		accountNumberPlaceholder: "আপনার অ্যাকাউন্ট নম্বর লিখুন",
		selectBank: "ব্যাংক নির্বাচন করুন",
		selectBankPlaceHolder: "আপনার ব্যাংক অ্যাকাউন্ট নির্বাচন করুন",
		branch: "শাখা",
		branchPlaceHolder: "আপনার ব্যাংক শাখা লিখুন",
		address: "ঠিকানা",
		addressPlaceholder: "ব্যাংক ঠিকানা লিখুন",
		selectCountry: "দেশ নির্বাচন করুন",
		selectCountryPlaceHolder: "আপনার ব্যাংক দেশ নির্বাচন করুন",
		selectCity: "শহর নির্বাচন করুন",
		selectCityPlaceHolder: "শহর নির্বাচন করুন",
		postalCode: "পোস্টাল কোড",
		postalCodePlaceHolder: "আপনার ব্যাংক পোস্টাল কোড লিখুন",
		buttonTitle: "সংরক্ষণ করুন",
		bankNameWarning: "আপনার ব্যাঙ্ক অ্যাকাউন্ট নাম লিখুন",
		bankNumberWarning: "আপনার ব্যাঙ্ক অ্যাকাউন্ট নম্বর লিখুন",
		selectBankWarning: "একটি ব্যাঙ্ক নির্বাচন করুন",
		branchWarning: "দয়া করে আপনার ব্যাংক শাখা লিখুন",
		addressWarning: "দয়া করে আপনার ঠিকানা লিখুন",
		cityWarning: "দয়া করে আপনার শহর লিখুন",
	},
	addedBankList: {
		pageTitle: "ব্যাংক তালিকা",
		acName: "একাউন্ট নাম",
		acNumber: "একাউন্ট নাম্বার",
		branch: "শাখা",
		address: "ঠিকানা",
		city: "শহর",
		country: "দেশ",
		addBank: "আপনার ব্যাংক অ্যাকাউন্ট যোগ করুন",
	},
	blackListed: {
		pageTitle: "ব্ল্যাকলিস্টেড",
		warningMsg: "আপনার ব্যবসা ব্ল্যাকলিস্টে রয়েছে",
		instrMsg:
			"আপনার ব্যবসার মালিককে অনুরোধ করুন EkkBaz এ যোগাযোগ করে প্রয়োজনীয় ব্যবস্থা নিয়ে পুনরায় আপনার ব্যবসা সক্রিয় করুন।",
		btnLabel: "লগ আউট",
	},
	industryType: {
		pageTitle: "প্রাইমারী ইন্ডাস্ট্রি",
		title: "অনুগ্রহ করে আপনার ইন্ডাস্ট্রি নির্বাচন করুন",
	},
	accounting: {
		todayRevenue: "আজকের মোট আয়",
		todayExpense: "আজকের মোট ব্যয়",
		revenues: "আয়",
		expenses: "ব্যয়",
		accPageTitle: "অ্যাকাউন্টস",
		revenue: "আয়",
		expense: "ব্যয়",
		addRevenue: "আয় যোগ করুন",
		addExpense: "ব্যয় যোগ করুন",
		date: "তারিখ",
		amount: "পরিমাণ",
		amountPlaceholder: "পরিমাণ লিখুন",
		category: "ক্যাটাগরি",
		categoryPlaceholder: "একটি ক্যাটাগরি নির্বাচন করুন",
		description: "বর্ণনা",
		descriptionPlaceholder: "বর্ণনা লিখুন",
		images: "ছবি আপলোড করুন",
		supplier: "সাপ্লায়ার",
		supplierPlaceholder: "একটি সাপ্লায়ার নির্বাচন করুন",
		submitRevenue: "আয় জমা দিন",
		submitExpense: "ব্যয় জমা দিন",
		uploadImageInfo: "আপনার ছবি এখানে আপলোড করুন, অথবা ব্রাউজ করুন",
		uploadImageInfoPlaceholder:
			"সর্বাধিক ফাইলের আকার ১০ এমবি - JPG, JPEG অথবা PNG",
		revenueCreateWarning: "আপনি কি এই আয় প্রতিবেদন জমা দিতে নিশ্চিত?",
		expenseCreateWarning: "আপনি কি এই ব্যয় প্রতিবেদন জমা দিতে নিশ্চিত?",
		successRevenue: "আয় সফলভাবে তৈরি হয়েছে",
		successExpense: "ব্যয় সফলভাবে তৈরি হয়েছে",
		supplierPageTitle: "সাপ্লায়ার তালিকা",
		alreadyPaid: "ব্যয় পরিশোধিত হয়েছে?",
		onto: "কবে পরিশোধিত হবে",
		paid: "পরিশোধিত",
		notPaid: "অপরিশোধিত",
		takePhoto: "ছবি তুলুন",
		addImage: "ছবি যোগ করুন",
		successFullyCreated: "সফলভাবে তৈরি হয়েছে",
		amountCategoryDescription:
			"দয়া করে একটি বৈধ পরিমাণ যোগ করুন, একটি বিভাগ নির্বাচন করুন এবং একটি বর্ণনা লিখুন",
		amountDescription:
			"দয়া করে একটি বৈধ পরিমাণ যোগ করুন এবং একটি বর্ণনা লিখুন",
		amountCategory:
			"দয়া করে একটি বৈধ পরিমাণ যোগ করুন এবং একটি বিভাগ নির্বাচন করুন।",
		categoryDescription:
			"দয়া করে একটি বিভাগ নির্বাচন করুন এবং একটি বর্ণনা লিখুন",
		amountWarning: "দয়া করে একটি বৈধ পরিমাণ লিখুন।",
		descriptionWarning: "দয়া করে একটি বৈধ বর্ণনা লিখুন।",
		categoryWarning: "দয়া করে একটি বিভাগ নির্বাচন করুন।",
		taxAmount: "ট্যাক্সের পরিমাণ",
		taxAmountPlaceholder: "ট্যাক্সের পরিমাণ লিখুন",
		totalCollectedAmount: "মোট সংগ্রহিত পরিমাণ",
	},
	employees: {
		pageTitle: "কর্মী তালিকা",
		createNewEmployees: "নতুন কর্মী তৈরী করুন",
		searchPlaceHolder: "আপনার কর্মী খুঁজুন",
		collectorList: "কালেক্টরের তালিকা",
		collectorSearch: "কালেক্টর অনুসন্ধান করুন",
	},
	employeesDetails: {
		pageTitle: "কর্মীর বিবরণ",
	},
	createEmployees: {
		pageTitle: "নতুন কর্মী তৈরী করুন",
		customerNoHolder: "মোবাইল নম্বর লিখুন",
		employeeNumber: "কর্মীর নম্বর",
		name: "নাম",
		namePlaceholder: "কর্মীর নাম লিখুন",
		confirmBtn: "নিশ্চিত করুন",
		selectEmployees: "কর্মী নির্বাচন করুন",
		nameError: "অনুগ্রহ করে কর্মীর নাম লিখুন",
		roleError: "অনুগ্রহ করে কর্মীর রোল নির্বাচন করুন",
		edit: "ইডিট করুন",
		delete: "ডিলিট করুন",
		editEmployee: "ইডিট কর্মী",
		deleteConformationAsk: "আপনি কি এই কর্মীকে মুছে দেওয়ার বিষয়ে নিশ্চিত?",
		updateEmployee: "কর্মী আপডেট",
	},
	loan: {
		pageTitle: "ঋণ",
		myLoanRequest: "আমার ঋণ অনুরোধ",
		loanProviders: "ঋণ প্রদানকারী পণ্য",
		loanAmount: "প্রয়োজনীয় ঋণের পরিমাণ",
		loanPurpose: "ঋণের উদ্দেশ্য",
		country: "দেশ",
		loanDuration: "ঋণের সময়কাল",
		creditScore: "ক্রেডিট স্কোর",
		createLoanRequest: "ঋণ অনুরোধ তৈরি করুন",
		loanCreateWarning: "আপনি কি এই ঋণ অনুরোধ তৈরি করতে চান?",
	},
};
export default bangla;
