import React from "react";

export default function JSONPreview({ fileName, fileUrl }) {
  return (
    <div>
      <a
        className="cursor-pointer"
        href={fileUrl}
        target="_blank"
        rel="noreferrer"
      >
        <h3 className="font-bold">{fileName}</h3>
      </a>
    </div>
  );
}
