import { createSlice } from "@reduxjs/toolkit";

const buzAiSlice = createSlice({
  name: "buzAi",
  initialState: {
    data: [],
    thread: null,
    procApiCall: true,
  },
  reducers: {
    setNewMessage: (state, action) => {
      state.data = action.payload;
    },
    setNewThread: (state, action) => {
      state.thread = action.payload;
    },
    procApiCall: (state, action) => {
      state.procApiCall = action.payload;
    },

    clearThread: (state) => {
      state.data = [];
      state.thread = null;
      state.procApiCall = true;
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = buzAiSlice;

export const setNewMessage = (values) => (dispatch, getState) => {
  if ((values[0].type === "cancel") | (values[0].type === "accept")) {
    let lastElement =
      getState().bazAi.data[getState().bazAi.data.length - 1].id;
    let updateElement = getState().bazAi.data?.map((el) => {
      return el?.id === lastElement
        ? {
            ...el,
            orderState: "hide",
          }
        : el;
    });
    let data = [...updateElement, ...values];
    dispatch(actions.setNewMessage(data));
  } else {
    let data = [...getState().bazAi.data, ...values];
    dispatch(actions.setNewMessage(data));
  }
};
export const setUpdateMessage = (values) => (dispatch, getState) => {
  console.log(values);

  if (Array.isArray(values)) {
    let removeElement =
      getState().bazAi.data[getState().bazAi.data.length - 1].id;
    let remainingItems = getState().bazAi.data.filter(
      (el) => el.id !== removeElement
    );
    let updatedData = [...remainingItems, ...values];
    dispatch(actions.setNewMessage(updatedData));
  } else {
    let data = getState().bazAi.data.map((el) => {
      if (values?.updateImage || values?.updateCad) {
        return el.id ===
          getState().bazAi.data[getState().bazAi.data.length - 2].id
          ? {
              ...el,
              ...values,
            }
          : el;
      } else {
        return el.id ===
          getState().bazAi.data[getState().bazAi.data.length - 1].id
          ? {
              ...el,
              ...values,
            }
          : el;
      }
    });
    dispatch(actions.setNewMessage(data));
  }
};

export const setUpdateReceiverMessage = (values) => (dispatch, getState) => {
  let removeElement =
    getState().bazAi.data[getState().bazAi.data.length - 1].id;
  let remainingItems = getState().bazAi.data.filter(
    (el) => el.id !== removeElement
  );
  let updatedData = [...remainingItems, ...values];
  dispatch(actions.setNewMessage(updatedData));
};

export const setUpdateItemContent = (values) => (dispatch, getState) => {
  let findEle = getState().bazAi.data.find((e) => e.uid === values.uid);
  let remainingItems = getState().bazAi.data.filter(
    (el) => el.uid !== values.uid
  );
  let updatedData = [...remainingItems, values];
  dispatch(actions.setNewMessage(updatedData));
};

export const setUpdateEmailContent = (values) => (dispatch, getState) => {
  let findEle = getState().bazAi.data.find((e) => e.uid === values.uid);
  let lastItem = getState().bazAi.data[getState().bazAi.data.length - 1];
  let items = [
    values,
    { ...lastItem, loading: false, message: "Your email successfully sent." },
  ];
  let remainingItems1 = getState().bazAi.data.filter(
    (el) => el.uid !== values.uid
  );
  let remainingItems2 = remainingItems1.filter((el) => !el.loading);
  let updatedData = [...remainingItems2, ...items];
  dispatch(actions.setNewMessage(updatedData));
};

export const setLoaderMessage = (values) => (dispatch, getState) => {
  let updatedData = [...getState().bazAi.data, ...values];
  dispatch(actions.setNewMessage(updatedData));
};

export const setNewThread = (value) => (dispatch, getState) => {
  dispatch(actions.setNewThread(value));
};
export const procApiCall = (value) => (dispatch, getState) => {
  dispatch(actions.procApiCall(value));
};
export const clearThread = () => (dispatch) => {
  dispatch(actions.clearThread());
};
// Export the reducer, either as a default or named export
export default reducer;
