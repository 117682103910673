import React, { useState } from "react";
import LandingNavBar from "./LandingNavBar";
import TextBannerArea from "./TextBannerArea";
import PhoneNumber from "./PhoneNumber";
import { useSelector } from "react-redux";
import { getUserBusiness } from "config/user";
import { Navigate } from "react-router-dom";
import CheckBusiness from "components/CheckBusiness";
import { selectors } from "redux/container/authSlice";
import { setLanguage } from "config/language";
import FooterV2 from "./FooterV2";

export default function PhoneNumberCheck() {
	const [countryCode, setCountryCode] = useState("+1");
	const [country, setCountry] = useState("United States");
	const [phoneNo, setPhoneNo] = useState("");
	var sSize = window.matchMedia("(max-width: 599px)");
	const { loginStatus } = useSelector(selectors.getAuth);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	if (loginStatus) {
		let business = getUserBusiness();
		if (business) {
			return <Navigate to='/home' />;
		} else {
			return <CheckBusiness />;
		}
	}

	if (localStorage.getItem("lan") === null) {
		setLanguage();
	}
	return (
		<>
			<LandingNavBar />
			<div className='container w-full h-screen flex items-center justify-center lg:flex-row flex-col overflow-y-scroll'>
				{/* <TextBannerArea ekkLanguage={ekkLanguage} /> */}
				<PhoneNumber
					sSize={sSize}
					phoneNo={phoneNo}
					setPhoneNo={setPhoneNo}
					country={country}
					setCountry={setCountry}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
				/>
			</div>
			<FooterV2 />
		</>
	);
}
