import Minus from "assets/images/icon/svg/Minus";
import Plus from "assets/images/icon/svg/Plus";
import React, { useEffect, useRef, useState } from "react";

export default function QtyController({
  data,
  item,
  initQty,
  minusCartItem,
  plusCartItem,
  updateLastDataHandler,
}) {
  const [qty, setQty] = useState(initQty);
  const numberInputRef = useRef(null);
  useEffect(() => {
    const handleWheel = (event) => {
      if (
        numberInputRef.current &&
        numberInputRef.current === document.activeElement
      ) {
        event.preventDefault();
      }
    };
    window.addEventListener("wheel", handleWheel, { passive: false });
    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div>
      <div className="flex">
        <div>
          <span
            className="bg-primary w-6 h-6 flex justify-center items-center rounded-md leading-6 cursor-pointer"
            style={{
              boxShadow: "0px 3px 10px rgba(0,0,0,.15)",
              userSelect: "none",
            }}
            onClick={() => {
              if (qty > 1) {
                let updateQuery = {
                  productInitQty: data?.productInitQty - 1,
                  message: `Create Order \nSupplier: ${
                    data?.productQty?.seller_business_name
                  } \n${data?.productQty?.title} \n${
                    data?.productQty?.country?.currency_symbol_native
                  }${data?.productQty?.procure_price}x${
                    data?.productInitQty - 1
                  } = ${
                    data?.productQty?.country?.currency_symbol_native
                  }${Number(
                    data?.productQty?.procure_price * (data?.productInitQty - 1)
                  ).toFixed(2)}\nTotal = ${
                    data?.productQty?.country?.currency_symbol_native
                  }${Number(
                    data?.productQty?.procure_price * (data?.productInitQty - 1)
                  ).toFixed(2)}`,
                };
                updateLastDataHandler({
                  ...data,
                  ...updateQuery,
                });
                setQty(qty - 1);
              }
            }}
          >
            <Minus />
          </span>
        </div>

        <div className=" px-3">
          <input
            ref={numberInputRef}
            className={`appearance-none w-10 rounded-md h-6 leading-6 text-center ${
              qty >= initQty ? "bg-F3F3F3" : "bg-F94B41-200"
            }  `}
            type="number"
            value={qty}
            onChange={(e) => {
              if (e.target.value > -1) {
                setQty(e.target.value);
                let updateQuery = {
                  productInitQty: e.target.value,
                  message: `Create Order \nSupplier: ${
                    data?.productQty?.seller_business_name
                  } \n${data?.productQty?.title} \n${
                    data?.productQty?.country?.currency_symbol_native
                  }${data?.productQty?.procure_price}x${e.target.value} = ${
                    data?.productQty?.country?.currency_symbol_native
                  }${Number(
                    data?.productQty?.procure_price * e.target.value
                  ).toFixed(2)}\nTotal = ${
                    data?.productQty?.country?.currency_symbol_native
                  }${Number(
                    data?.productQty?.procure_price * e.target.value
                  ).toFixed(2)}`,
                };
                updateLastDataHandler({
                  ...data,
                  ...updateQuery,
                });
              } else {
                setQty(1);
              }
            }}
          ></input>
          {/* {!(qty >= item?.minimum_order_quantity) && (
                        <div className='text-right text-10 font-bold text-primary pt-2'>
                            {ekkLanguage.createOrder.lessThenMoq}
                        </div>
                    )} */}
          {/* <ProductQuantityInput value={item.quantity} product={item} /> */}
        </div>
        <div>
          <span
            className="bg-success w-6 h-6 flex justify-center items-center rounded-md leading-6 cursor-pointer"
            style={{
              boxShadow: "0px 3px 10px rgba(0,0,0,.15)",
              userSelect: "none",
            }}
            onClick={() => {
              let updateQuery = {
                productInitQty: data?.productInitQty + 1,
                message: `Create Order \nSupplier: ${
                  data?.productQty?.seller_business_name
                } \n${data?.productQty?.title} \n${
                  data?.productQty?.country?.currency_symbol_native
                }${data?.productQty?.procure_price}x${
                  data?.productInitQty + 1
                } = ${
                  data?.productQty?.country?.currency_symbol_native
                }${Number(
                  data?.productQty?.procure_price * (data?.productInitQty + 1)
                ).toFixed(2)}\nTotal = ${
                  data?.productQty?.country?.currency_symbol_native
                }${Number(
                  data?.productQty?.procure_price * (data?.productInitQty + 1)
                ).toFixed(2)}`,
              };
              updateLastDataHandler({
                ...data,
                ...updateQuery,
              });
              setQty(qty + 1);
            }}
          >
            <Plus />
          </span>
        </div>
      </div>
    </div>
  );
}
