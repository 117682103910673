import {
	EditorState,
	Modifier,
	ContentState,
	convertFromHTML,
	convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { toast } from "react-toastify";
import draftToHtml from "draftjs-to-html";
import { useState } from "react";
import Select from "react-select";

const EmailEditorCard = ({
	handleClose,
	introString,
	getAboutData,
	from,
	teamId,
}) => {
	const [loading, setLoading] = useState();
	const [editorState, setEditorState] = useState(() =>
		EditorState.createEmpty()
	);

	// const [editorState, setEditorState] = useState(() => {
	//   if (introString.length) {
	//     return EditorState.createWithContent(
	//       ContentState.createFromBlockArray(convertFromHTML(introString))
	//     );
	//   } else {
	//     return EditorState.createEmpty();
	//   }
	// });

	const [convertedContent, setConvertedContent] = useState(null);
	const handleEditorChange = (state) => {
		setEditorState(state);
		convertContentToHTML();
	};

	const convertContentToHTML = () => {
		let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
		setConvertedContent(currentContentAsHTML);
	};
	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	// const SaveOption = ({ editorState, onChange }) => {
	// 	let [show, setShow] = useState(false);
	// 	let icons = [
	// 		"fas fa-user",
	// 		"fas fa-briefcase",
	// 		"fas fa-quote-left",
	// 		"fas fa-envelope",
	// 	];

	// 	const save = (el) => {
	// 		// const data = '<i className=fas fa-envelope></i>';
	// 		const data = "<span className=fas fa-envelope>hello</span>";
	// 		//       const { contentBlocks, entityMap } = convertFromHTML(data);
	// 		//

	// 		let { contentBlocks, entityMap } = htmlToDraft(data);

	// 		let contentState = Modifier.replaceWithFragment(
	// 			editorState.getCurrentContent(),
	// 			editorState.getSelection(),
	// 			ContentState.createFromBlockArray(
	// 				contentBlocks,
	// 				entityMap
	// 			).getBlockMap()
	// 		);
	// 		//  const contentState = Modifier.replaceText(
	// 		//    editorState.getCurrentContent(),
	// 		//    editorState.getSelection(),
	// 		//    ContentState.createFromBlockArray(
	// 		//      contentBlocks,
	// 		//      entityMap
	// 		//    ).getBlockMap()
	// 		//  );
	// 		onChange(
	// 			EditorState.push(editorState, contentState, "insert-fragment")
	// 		);
	// 		// onChange(
	// 		//   EditorState.push(editorState, contentState, "insert-characters")
	// 		// );
	// 	};

	// 	return (
	// 		<div className='position-relative'>
	// 			<div onClick={() => setShow(!show)} className='rdw-option-wrapper'>
	// 				<i className='fa fa-address-book'></i>
	// 			</div>
	// 			<div
	// 				className='rdw-emoji-modal'
	// 				style={show ? { display: "flex" } : { display: "none" }}>
	// 				{icons.map((el) => {
	// 					return (
	// 						<i
	// 							className={`rdw-emoji-icon ${el}`}
	// 							onClick={() => save(el)}></i>
	// 					);
	// 				})}
	// 			</div>
	// 		</div>
	// 	);
	// };

	// let introUpdateHandler = async function () {
	// 	let res;
	// 	if (from === "profile") {
	// 		res = await fanProfile.userAboutUpdate({
	// 			intro: draftToHtml(convertToRaw(editorState.getCurrentContent())),
	// 		});
	// 	} else if (from === "team") {
	// 		res = await team.teamAboutUpdate(teamId, {
	// 			intro: draftToHtml(convertToRaw(editorState.getCurrentContent())),
	// 		});
	// 	} else if (from === "coachProfile") {
	// 		res = await fanProfile.userAboutUpdate({
	// 			service: draftToHtml(convertToRaw(editorState.getCurrentContent())),
	// 		});
	// 	}

	// 	if (res.status === 200) {
	// 		toast.success("Successfully updated");
	// 		// toast.success(
	// 		//   from === "coachProfile"
	// 		//     ? "Services updated successfully"
	// 		//     : "Intro updated successfully"
	// 		// );
	// 		setEditorState(() => EditorState.createEmpty());
	// 		getAboutData();
	// 		handleClose();
	// 	}
	// };

	// useEffect(() => {
	// 	setEditorState(
	// 		EditorState.createWithContent(
	// 			ContentState.createFromBlockArray(convertFromHTML(introString))
	// 		)
	// 	);
	// }, [introString]);

	return (
		<div>
			<div className='App'>
				<div className={"w-full mb-4"}>
					<div
						className='email-to-box'
						style={{
							display: "flex",
							alignItems: "center",
							border: "1px solid #00000015",
							borderRadius: "0",
							padding: "0 8px",
						}}>
						<div
							style={{
								fontSize: "14px",
								fontWeight: "600",
								marginRight: "12px",
							}}>
							To
						</div>
						<Select
							isSearchable={false}
							placeholder='Select supplier'
							isMulti={true}
							styles={{
								control: (baseStyles, state) => ({
									...baseStyles,
									width: "100%",
									height: 40,
									border: "none", // Remove border for the select control itself
									outline: "none",
									fontWeight: 600,
									fontSize: 12,
									borderRadius: 0,
									paddingLeft: "8px",
								}),
							}}
							theme={(theme) => ({
								...theme,
								borderRadius: 0,
								border: "none",
								colors: {
									...theme.colors,
									primary25: "#00000015",
									primary: "#00000015",
								},
							})}
						/>
					</div>
				</div>
				<Editor
					editorState={editorState}
					onEditorStateChange={handleEditorChange}
					wrapperClassName='wrapper-class'
					toolbarClassName='toolbar-class'
					editorClassName='editor-class'
					contenteditable='true'
					placeholder='write your custom email'
					// toolbarCustomButtons={[<SaveOption />]}
					toolbar={{
						options: [
							"inline",
							// "blockType",
							"fontSize",
							"fontFamily",
							"list",
							// "textAlign",
							// "colorPicker",
							"link",
							// "embedded",
							"emoji",
							// "image",
							// "remove",
							// "history",
						],
						emoji: {
							emojis: [
								"⚽️",
								"🏀",
								"🏈",
								"⚾️",
								"🏉",
								"🥏",
								"🎱",
								"🪀",
								"🏓",
								"🏸",
								"🏒",
								"🏑",
								"🥍",
								"🏏",
								"🪃",
								"🥅",
								"⛳️",
								"🪁",
								"🏹",
								"🎣",
								"🤿",
								"🥊",
								"🥋",
								"🎽",
								"🛹",
								"🛼",
								"🛷",
								"⛸",
								"🥌",
								"🎿",
								"⛷",
								"🏂",
								"🪂",
								"🏋️‍♀️",
								"🏋️",
								"🤼‍♀️",
								"🤼",
								"🤸‍♀️",
								"🤸",
								"⛹️‍♀️",
								"⛹️",
								"🤺",
								"🤾‍♀️",
								"🤾",
								"🏌️‍♀️",
								"🏌️",
								"🏇",
								"🧘‍♀️",
								"🧘",
								"🏄‍♀️",
								"🏄‍♂️",
								"🏊‍♀️",
								"🏊",
								"🤽‍♀️",
								"🤽",
								"🚣‍♀️",
								"🚣",
								"🧗‍♀️",
								"🧗",
								"🚵‍♀️",
								"🚵",
								"🚴‍♀️",
								"🚴",
								"🏆",
								"🥇",
								"🥈",
								"🥉",
								"🏅",
								"🎖",
								"🏵",
								"🎗",
								"🎫",
								"🎟",
								"🎪",
								"🤹",
								"🤹‍♂️",
								"🤹‍♀️",
								"🎭",
								"🩰",
								"🎨",
								"🎬",
								"🎤",
								"🎧",
								"🎼",
								"🎹",
								"🥁",
								"🪘",
								"🎷",
								"🎺",
								"🪗",
								"🎸",
								"🪕",
								"🎻",
								"🎲",
								"♟",
								"🎯",
								"🎳",
								"🎮",
								"🎰",
								"🧩",
								"🧮",
								"📌",
								"🛴",
								"🌏",
								"👍",
								"👎",
								"🙏",
							],
						},
						inline: {
							options: [
								"bold",
								"italic",
								"underline",
								"strikethrough",
								"monospace",
							],
						},
						list: {
							options: ["unordered", "ordered"],
						},
					}}
				/>

				{/* <textarea
              disabled
              value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            /> */}
			</div>
			{/* <div className='modal-footer-buttons d-flex justify-content-between'>
				<Button
					disabled={loading}
					onClick={() => {
						handleClose();
					}}>
					Cancel
				</Button>
				<div className='action-buttons'>
					<Button
						type='submit'
						className='btn btn-primary'
						disabled={loading}
						onClick={introUpdateHandler}>
						{loading && <CSpinner />}
						Update {from === "coachProfile" ? "Services" : "Intro"}
					</Button>
				</div>
			</div> */}
		</div>
	);
};

export default EmailEditorCard;
