import {
	IonApp,
	IonCol,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { digitChanger } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Accounts from "services/Accounts";

export default function PaymentDuePartsPage() {
	const location = useLocation();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let [loading, setLoading] = useState(false);
	let [expenseData, setExpenseData] = useState([]);
	const [accountType, setAccountType] = useState(null);
	const [pageCount, setPageCount] = useState(2);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const searchParams = new URLSearchParams(location?.search);
	const start = moment(searchParams.get("state")).format("YYYY-MM-DD");
	const end = moment(searchParams.get("end")).format("YYYY-MM-DD");

	const getRevenueData = async () => {
		let accountType = await Accounts.getAccountType("Expense");
		if (accountType.success) {
			setAccountType(accountType?.results?.id);
			setLoading(true);
			let response = await Accounts.getDuePaymentDateWays(
				accountType?.results?.id,
				start,
				end
			);
			if (response.status === 200) {
				setLoading(false);
				setExpenseData(response.results);
			} else {
				setLoading(false);
			}
		}
	};
	useEffect(() => {
		getRevenueData();
	}, []);

	let loadMoreTran = async () => {
		if (hasMoreData) {
			let response = await Accounts.getDuePaymentDateWays(
				accountType,
				start,
				end,
				pageCount
			);
			if (response.status === 200) {
				setLoading(false);
				setPageCount((prev) => prev + 1);
				setExpenseData([...expenseData, ...response.results]);
			} else {
				setHasMoreData(false);
				setLoading(false);
			}
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title='Expense Payment Due' />
					<div className='mt-14 px-4'>
						<div className='pb-10'>
							<div className='pt-3'>
								<div className='px-2 flex justify-between items-center pt-1 pb-5'>
									<h4 className='font-bold text-20 text-black-1000'>
										Expense Payment Due On This Date
									</h4>
								</div>
								<div>
									{/* <div className='flex justify-between items-center mb-6'>
							<div>
								<Select
									onFocus={(e) => (e.target.readOnly = true)}
									defaultValue={typeOptions[1]}
									options={typeOptions}
									onChange={selectStatusChanger}
									placeholder='Select Status'
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											width: "300px",
											height: 40,
											border: "1px solid #999",
											outLine: "none",
											fontWeight: 500,
											fontSize: 12,
											color: "blue",
											borderRadius: 5,
										}),
										placeholder: (baseStyles, state) => ({
											...baseStyles,
											// color: "#FF564C",
										}),
										singleValue: (baseStyles, state) => ({
											...baseStyles,
											// color: "#00B224",
											fontWeight: 700,
										}),
										indicatorSeparator: (baseStyles, state) => ({
											...baseStyles,
											display: "none",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											// color: status ? "#00B224" : "#FF564C",
											// color: "#999",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											backgroundColor: state.isSelected
												? "#f6f6f6"
												: "#fff", // Darker background for selected option
											color: state.isSelected ? "#000" : "#666", // Dark text for selected
											"&:hover": {
												backgroundColor: "#f2f2f2", // Hover effect
											},
										}),
									}}
									theme={(theme) => ({
										...theme,
										borderRadius: 5,
										colors: {
											...theme.colors,
											// primary25: "rgb(204, 255, 214)",
											// primary: "#00FF33",
											primary25: "#efefef",
											primary: "#f6f6f6",
										},
									})}
								/>
							</div>

							<div>
								<IonToggle
									labelPlacement='start'
									height='100'
									aria-label='Primary toggle'
									size='large'
									color='success'
									checked={isPaid}
									onIonChange={handleToggleChange}>
									<span className='font-bold text-14'>Paid</span>
								</IonToggle>
							</div>
						</div> */}

									<div>
										<table
											className='table-auto w-full bg-white'
											style={{
												boxShadow:
													"0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
											}}>
											<thead>
												<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
													<th className='border p-1 font-bold text-12'>
														S/N
													</th>
													{/* <th className='border p-1 font-bold text-14'>
														Order Id
													</th> */}

													<th className='border p-1 font-bold text-14'>
														Amount
													</th>
													<th className='border p-1 font-bold text-14'>
														Next To Payment
													</th>
												</tr>
											</thead>
											<tbody>
												{expenseData?.length
													? expenseData?.map((el, i) => {
															return (
																<tr className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4 cursor-pointer'>
																	<td className='border p-2 w-14 font-semibold text-10'>
																		{digitChanger(i + 1)}
																	</td>
																	<td
																		// onClick={() =>
																		// 	handleNavigate(
																		// 		e.productTitle,
																		// 		e.catalogId
																		// 	)
																		// }
																		className='border  font-semibold p-2 text-14'>
																		{el?.amount}
																	</td>
																	<td className='border  font-semibold p-2 text-14'>
																		{el?.paid_date}
																	</td>
																</tr>
															);
													  })
													: ""}
											</tbody>
										</table>
										{loading ? (
											<div className='flex items-center justify-center h-[156px] m-auto'>
												{" "}
												<IonSpinner name='crescent'></IonSpinner>
											</div>
										) : (
											""
										)}
										{expenseData?.length === 0 && (
											<>
												<IonCol size='2'></IonCol>
												<IonCol size='8'>
													<div
														className='text-center flex items-center justify-center'
														style={{
															height: "calc(100vh - 420px)",
														}}>
														<div>
															<img
																className='m-auto mb-[10px] mt-5'
																src='/assets/images/not-found-file.png'
																alt='emptyCard'
															/>
															<p className='text-16 font-normal text-222222 mb-[35px]'>
																{ekkLanguage.orderList.notFound}
															</p>
														</div>
													</div>
												</IonCol>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							loadMoreTran();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 1000);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
