import React from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import DXFRenderer from "./DXFRenderer"; // Import the updated DXFRenderer

const DXFViewer = ({ fileContent }) => {
  return (
    <div>
      {fileContent && (
        <Canvas
          style={{ width: "100%", height: "480px" }}
          camera={{ position: [0, 0, 40], fov: 50 }}
        >
          <ambientLight intensity={1} />
          <spotLight position={[10, 10, 10]} intensity={2} />
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 10]} intensity={1} />

          <DXFRenderer dxfContent={fileContent} />
          <OrbitControls />
        </Canvas>
      )}
    </div>
  );
};

export default DXFViewer;
