import React from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ReferenceLine,
	ResponsiveContainer,
	Cell,
} from "recharts";

export default function ProfitLossChart({ transaction, barClickHandler }) {
	return (
		<div style={{ width: "100%", height: 400 }}>
			<ResponsiveContainer>
				<BarChart
					data={transaction}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 5,
					}}>
					<CartesianGrid stroke='#ccc' strokeWidth={0.5} />
					<XAxis dataKey='name' interval={0} tick={{ fontSize: 12 }} />
					<YAxis tick={{ fontSize: 12 }} />
					<Tooltip
						cursor={{ fill: "transparent" }} // Removes the gray background
					/>
					<ReferenceLine y={0} stroke='#ccc' />
					<Bar dataKey='value' barSize={40}>
						{transaction?.length &&
							transaction.map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={entry.value >= 0 ? "#00B224" : "#FB766E"}
									onClick={() => barClickHandler(entry, "profitLoss")}
								/>
							))}
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
}
// import React, { useRef } from "react";
// import {
// 	Chart as ChartJS,
// 	CategoryScale,
// 	LinearScale,
// 	BarElement,
// 	Title,
// 	Tooltip,
// 	Legend,
// } from "chart.js";
// import { Bar, getElementAtEvent } from "react-chartjs-2";

// // Register required components
// ChartJS.register(
// 	CategoryScale,
// 	LinearScale,
// 	BarElement,
// 	Title,
// 	Tooltip,
// 	Legend
// );

// export default function ProfitLossChart({ transaction, barClickHandler }) {
// 	const chartRef = useRef(null);

// 	// Chart data
// 	const data = {
// 		labels: transaction?.map((entry) => entry.name),
// 		datasets: [
// 			{
// 				label: "Profit/Loss",
// 				data: transaction.map((entry) => entry.value),
// 				backgroundColor: transaction.map((entry) =>
// 					entry.value >= 0 ? "#00B224" : "#FB766E"
// 				),
// 			},
// 		],
// 	};

// 	// Chart options
// 	const options = {
// 		responsive: true,
// 		plugins: {
// 			legend: {
// 				display: false, // Hide the legend
// 			},
// 			tooltip: {
// 				callbacks: {
// 					label: (tooltipItem) => `Value: ${tooltipItem.raw}`, // Tooltip customization
// 				},
// 			},
// 		},
// 		scales: {
// 			x: {
// 				grid: {
// 					display: false, // Optional: Disable grid lines for X-axis
// 				},
// 			},
// 			y: {
// 				beginAtZero: true,
// 				grid: {
// 					color: "#ccc", // Optional: Change Y-axis grid color
// 				},
// 			},
// 		},
// 	};

// 	// Handle bar click
// 	const onBarClick = (event) => {
// 		const dataset = getElementAtEvent(chartRef.current, event);
// 		if (dataset.length > 0) {
// 			const index = dataset[0].index;
// 			const barData = transaction[index];
// 			barClickHandler(barData, "profitLoss");
// 		}
// 	};

// 	return (
// 		<div style={{ width: "100%", height: 400 }}>
// 			<Bar
// 				ref={chartRef}
// 				data={data}
// 				options={options}
// 				onClick={onBarClick}
// 			/>
// 		</div>
// 	);
// }
