import React, { useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { useGLTF, OrbitControls, Environment } from "@react-three/drei";

function Model({ file, setMaterials }) {
  const { scene } = useGLTF(file);

  // Traverse the scene to adjust materials and orientation
  scene.traverse((child) => {
    if (child.isMesh) {
      // Adjust material emissive color for better visibility
      child.material.emissive = child.material.color
        .clone()
        .multiplyScalar(0.1);
      child.material.needsUpdate = true;
    }
  });
  useEffect(() => {
    const materials = [];
    // Traverse the scene to collect materials
    scene.traverse((child) => {
      if (child.isMesh && child.material) {
        // Extract relevant material properties
        const materialData = {
          name: child.material.name || `Material_${materials.length + 1}`, // Material name
          type: child.material.type || "Unknown", // Material type
          uuid: child.material.uuid, // Unique ID
          color: child.material.color?.getHexString() || null, // Base color
          emissive: child.material.emissive?.getHexString() || null, // Emissive color
          roughness:
            child.material.roughness !== undefined
              ? child.material.roughness
              : null, // Roughness value
          metalness:
            child.material.metalness !== undefined
              ? child.material.metalness
              : null, // Metalness value
          opacity:
            child.material.opacity !== undefined ? child.material.opacity : 1, // Opacity value
          transparent: child.material.transparent || false, // Transparency flag
          side: child.material.side, // Material side (front, back, or double)
          envMapIntensity: child.material.envMapIntensity || 1, // Environment map intensity
          blendColor: child.material.blendColor?.getHexString() || null, // Blend color
          envMapRotation: child.material.envMapRotation || [0, 0, 0, "XYZ"], // Environment map rotation
          alphaTest: child.material.alphaTest || 0, // Alpha test threshold
          depthWrite: child.material.depthWrite || false, // Depth write flag
          depthTest: child.material.depthTest || false, // Depth test flag
        };

        materials.push(materialData);
      }
    });
    setMaterials(materials); // Pass materials as JSON to the parent
  }, [scene, setMaterials]);

  return (
    <primitive
      object={scene}
      scale={[2.3, 3.3, 2.3]} // Adjust scale for full coverage
      rotation={[0, Math.PI, 0]} // Rotate 180 degrees for back-to-front alignment
      position={[0, 0.5, 0]} // Adjust vertical position
      dispose={null}
    />
  );
}

export default function CADPreviewForBazAI({ fileUrl, setMaterials }) {
  return (
    <Canvas
      camera={{ position: [0, 2, 8], fov: 50 }} // Adjusted camera for better framing
      style={{ width: "100%", height: "480px" }}
      // take the camera full height
      fullHeight
    >
      {/* Ambient light for global illumination */}
      <ambientLight intensity={0.8} />
      {/* Spotlight to highlight the model */}
      <spotLight
        position={[10, 20, 10]}
        angle={0.5}
        penumbra={1}
        intensity={1.5}
        castShadow
      />
      {/* Optional HDRI environment for realistic lighting */}
      <Environment preset="sunset" />

      <Suspense fallback={null}>
        <Model file={fileUrl} setMaterials={setMaterials} />
      </Suspense>

      {/* Controls for model interaction */}
      <OrbitControls enablePan={false} enableZoom={true} autoRotate />
    </Canvas>
  );
}
