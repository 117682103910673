import { useEffect } from "react";
import * as THREE from "three";
import DXFParser from "dxf-parser";
import { useThree } from "@react-three/fiber";

const DXFRenderer = ({ dxfContent }) => {
  const { scene } = useThree();

  useEffect(() => {
    if (dxfContent) {
      const parser = new DXFParser();
      try {
        const dxfData = parser.parseSync(dxfContent);
        const group = new THREE.Group();
        dxfData.entities.forEach((entity) => {
          let object = null;
          if (entity.type === "LINE") {
            const material = new THREE.LineBasicMaterial({ color: 0xff0000 }); // Red line
            const points = [
              new THREE.Vector3(entity.vertices[0].x, entity.vertices[0].y, 0),
              new THREE.Vector3(entity.vertices[1].x, entity.vertices[1].y, 0),
            ];
            const geometry = new THREE.BufferGeometry().setFromPoints(points);
            object = new THREE.Line(geometry, material);
          } else if (entity.type === "CIRCLE") {
            const geometry = new THREE.CircleGeometry(entity.radius, 32);
            const material = new THREE.MeshBasicMaterial({
              color: "red",
              wireframe: true,
            });
            object = new THREE.Mesh(geometry, material);
            object.position.set(entity.center.x, entity.center.y, 0);
          } else if (entity.type === "POLYLINE") {
            const material = new THREE.LineBasicMaterial({ color: "green" });
            const points = entity.vertices.map(
              (vertex) => new THREE.Vector3(vertex.x, vertex.y, 0)
            );

            const geometry = new THREE.BufferGeometry().setFromPoints(points);
            object = new THREE.Line(geometry, material);
          } else if (entity.type === "SPLINE") {
            const points = entity.controlPoints.map(
              (point) => new THREE.Vector3(point.x, point.y, 0)
            );

            const curve = new THREE.CatmullRomCurve3(points);
            const curveGeometry = new THREE.BufferGeometry().setFromPoints(
              curve.getPoints(50)
            );
            const material = new THREE.LineBasicMaterial({ color: "black" });
            object = new THREE.Line(curveGeometry, material);
          } else {
            console.warn("Unsupported DXF Entity Type:", entity.type);
          }
          if (object) {
            group.add(object);
          }
        });

        scene.add(group);
      } catch (error) {
        console.error("Failed to parse DXF file", error);
      }
    }
  }, [dxfContent, scene]);

  return null;
};

export default DXFRenderer;
