import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
// import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

const ModelViewer = ({ url }) => {
  const viewerRef = useRef(null);

  useEffect(() => {
    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, 200 / 200, 0.1, 1000);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(200, 200);
    viewerRef.current.appendChild(renderer.domElement);

    // Add lights to the scene
    const light1 = new THREE.DirectionalLight(0xffffff, 1);
    light1.position.set(5, 5, 5).normalize();
    scene.add(light1);

    const light2 = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(light2);

    // Load GLB model
    const loader = new GLTFLoader();

    // Ensure the model URL is a string before loading
    if (typeof url !== "string") {
      console.error("Model URL must be a string");
      return;
    }

    loader.load(
      url,
      (gltf) => {
        // Scale the model if necessary
        gltf.scene.scale.set(0.5, 0.5, 0.5); // Adjust the scale as needed
        scene.add(gltf.scene); // Add the loaded GLB model to the scene

        // Center the model in the scene
        const box = new THREE.Box3().setFromObject(gltf.scene);
        const center = box.getCenter(new THREE.Vector3());
        gltf.scene.position.sub(center); // Center the model

        renderer.render(scene, camera); // Initial render after model load
      },
      undefined,
      (error) => {
        console.error("Error loading model:", error);
      }
    );

    // Position the camera
    camera.position.z = 5;

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      const width = viewerRef.current.clientWidth; // Get current width
      const height = viewerRef.current.clientHeight; // Get current height
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      renderer.dispose();
      viewerRef.current.removeChild(renderer.domElement);
    };
  }, [url]); // Include url as a dependency

  return (
    <div
      ref={viewerRef}
      style={{
        minWidth: "200px",
        minHeight: "200px", // Changed height to match renderer
        overflow: "hidden",
      }}
    />
  );
};

export default ModelViewer;
