import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Accounts = {
	getCategory: (typeId) => {
		return SuperFetch(
			`${baseUrl.account}/category/?acc_group=${typeId}&page=1&limit=100`
		);
	},
	getCategorySearch: (q, typeId) => {
		return SuperFetch(
			`${baseUrl.account}/category/?title=${q}&acc_group=${typeId}&page=1&limit=100`
		);
	},
	getAccountGroup: (q) => {
		return SuperFetch(`${baseUrl.account}/acc-group/`);
	},
	getAccountList: (id, page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.account}/transaction/?acc_group=${id}&page=${page}&limit=${limit}`
		);
	},
	getAccountListSearch: (id, query, page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.account}/transaction/?acc_group=${id}&q=${query}&page=${page}&limit=${limit}`
		);
	},
	getAccountType: (type) => {
		return SuperFetch(`${baseUrl.account}/acc-group/?title=${type}`);
	},

	createTransaction: (query) => {
		return SuperFetch(`${baseUrl.account}/transaction/`, {
			method: "POST",
			data: query,
		});
	},
	updateTransaction: (id, query) => {
		return SuperFetch(`${baseUrl.account}/transaction/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	getTransaction: (id) => {
		return SuperFetch(`${baseUrl.account}/transaction/${id}`);
	},
	getTodayTransaction: () => {
		return SuperFetch(`${baseUrl.account}/today-transaction/`);
	},
	getTransactionDate: (typeId, start, end) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-chart/?acc_group=${typeId}&start_date=${start}&end_date=${end}`
		);
	},
	getProfitLoss: (start, end) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-profit-loss/?start_date=${start}&end_date=${end}`
		);
	},
	deleteItem: (id) => {
		return SuperFetch(`${baseUrl.account}/transaction/${id}/`, {
			method: "DELETE",
		});
	},
	getDashboardTransactions: (timeZone) => {
		return SuperFetch(
			`${baseUrl.account}/dashboard-transaction-chart/?timezone=${timeZone}`
		);
	},
	getDelayList: (type, isPaid, page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.account}/paid-unpaid-transaction/?acc_group=${type}&page=${page}&limit=${limit}&is_paid=${isPaid}`
		);
	},
	getDeuPaymentDate: (typeId, start, end) => {
		return SuperFetch(
			`${baseUrl.account}/paid-unpaid-dashboard-transaction-chart/?is_paid=False&acc_group=${typeId}&start_date=${start}&end_date=${end}`
		);
	},
	getTransactionDateWays: (typeId, start, end, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-chart-details/?acc_group=${typeId}&start_date=${start}&end_date=${end}&page=${page}&limit=${limit}`
		);
	},
	getDuePaymentDateWays: (typeId, start, end, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-chart-details/?acc_group=${typeId}&start_date=${start}&end_date=${end}&paid_date=True&is_paid=False&page=${page}&limit=${limit}`
		);
	},
	getProfitLossDateWays: (start, end, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.account}/transaction-chart-details/?start_date=${start}&end_date=${end}&page=${page}&limit=${limit}`
		);
	},
};
export default Accounts;
