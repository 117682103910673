import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleNSearch from "components/mobile-components/Common/TitleNSearch";
import { fetchOrder, searchOrder } from "redux/container/orderSlice";
import AccountsStatus from "components/mobile-components/Accounts/AccountsStatus";
import AccountBottomSheet from "components/mobile-components/Accounts/AccountBottomSheet";
import Accounts from "services/Accounts";
import NavFooter from "components/mobile-components/Common/NavFooter";

export default function AccountsPage() {
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [loading, setLoading] = useState(false);
	const [orderStatus, setOrderStatus] = useState("Revenue");
	const dispatch = useDispatch();
	const [text, setText] = useState("");
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const [pageCount, setPageCount] = useState(2);
	const [accountType, setAccountType] = useState(null);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [accountData, setAccountData] = useState([]);

	let getAccountType = async (title) => {
		let response = await Accounts.getAccountType(title ?? "Revenue");
		if (response?.success) {
			getAccountInfoList(response?.results?.id);
			setAccountType(response?.results?.id);
		}
	};
	useEffect(() => {
		getAccountType(orderStatus);
	}, []);

	let getAccountInfoList = async (accountId) => {
		setLoading(true);
		setPageCount(2);
		if (hasMoreData) {
			let response = await Accounts.getAccountList(accountId);
			if (!response?.results?.length) {
				setLoading(false);
				setHasMoreData(false);
			}
			if (response?.success) {
				setLoading(false);
				setAccountData(response?.results);
			} else {
				setLoading(false);
				setAccountData([]);
			}
		}
	};

	let getSearchData = async () => {
		setPageCount(2);
		setLoading(true);
		let response = await Accounts.getAccountType(orderStatus ?? "Revenue");
		if (response?.success) {
			let resp = await Accounts.getAccountListSearch(
				response?.results?.id,
				text,
				1,
				500
			);
			if (!resp?.results?.length) {
				setHasMoreData(false);
				setLoading(false);
			}
			if (resp?.success) {
				setLoading(false);
				setAccountData(resp?.results);
			} else {
				setLoading(false);
				setAccountData([]);
			}
		}
	};

	const deleteQueryHandler = async (e) => {
		setLoading(false);
		setText("");
		getAccountType(orderStatus);
	};

	let loadMoreTran = async () => {
		if (hasMoreData) {
			if (text) {
				let response = await Accounts.getAccountListSearch(
					accountType,
					text,
					pageCount
				);
				if (response.status === 200) {
					setLoading(false);
					setPageCount((prev) => prev + 1);
					setAccountData([...accountData, ...response.results]);
				} else {
					setHasMoreData(false);
					setLoading(false);
				}
			} else {
				let response = await Accounts.getAccountList(
					accountType,
					pageCount
				);
				if (response.status === 200) {
					setLoading(false);
					setPageCount((prev) => prev + 1);
					setAccountData([...accountData, ...response.results]);
				} else {
					setHasMoreData(false);
					setLoading(false);
				}
			}
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					// onIonScrollEnd={(ev) => {
					// 	getOrderList(orderStatus);
					// }}
				>
					<TitleNSearch
						title={ekkLanguage.accounting.accPageTitle}
						placeholder={`Search Your ${orderStatus}`}
						searchQ={text}
						setSearchQ={setText}
						fetchSearchData={getSearchData}
						initData={getAccountType}
						deleteQuery={deleteQueryHandler}
						inputType='string'
						from='accounts'
						status={orderStatus}
						ekkLanguage={ekkLanguage}
						setLoading={setLoading}
					/>
					{loading && (
						<IonLoading
							isOpen={true}
							message={ekkLanguage.orderList?.loader}
						/>
					)}
					<section>
						<AccountsStatus
							setOrderStatus={setOrderStatus}
							loading={loading}
							setLoading={setLoading}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}
							getAccountType={getAccountType}
							response={accountData}
						/>
					</section>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							loadMoreTran(orderStatus);
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 500);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
					<AccountBottomSheet />
				</IonContent>
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}
