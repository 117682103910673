import { Button } from "components/global-components/common/button";
import React, { useRef, useState } from "react";
import Auth from "services/Auth";
import { authBox_Block } from "Constants";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import EyeOn from "assets/images/icon/svg/EyeOn";
import EyeOff from "assets/images/icon/svg/EyeOff";
import LeftArrow2 from "assets/images/icon/svg/LeftArrow2";
import LandingNavBar from "./LandingNavBar";
import TextBannerArea from "./TextBannerArea";
import FooterV2 from "./FooterV2";

export default function ChangePasswordV2() {
	var sSize = window.matchMedia("(max-width: 599px)");
	let userInfo = useSelector((state) => state.user);
	const countryCode = userInfo?.createUserData?.countryCode;
	const country = userInfo?.createUserData?.country;
	const phoneNo = userInfo?.createUserData?.phoneNumber;
	const [loading, setLoading] = useState(false);
	const startLoading = () => setLoading(true);
	const stopLoading = () => setLoading(false);
	const [newPassword_forgotPassword, setNewPassword_forgotPassword] =
		useState("");
	const [confrimNewPassword, setConfrimNewPassword] = useState("");
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [error, setError] = useState("");
	const [lengthError, setLengthError] = useState("");
	const navigate = useNavigate();
	let [eyeIcon, setEyeIcon] = useState(false);
	let [eyeIconConfirm, setEyeIconConfirm] = useState(false);
	const [message, setMessage] = useState("");

	let clickBtnRef = useRef(null);
	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			clickBtnRef.click();
		}
	};
	const handlePassword = (passwordValue) => {
		const strengthChecks = {
			length: 0,
			hasUpperCase: false,
			hasLowerCase: false,
			hasDigit: false,
			// hasSpecialChar: false,
		};

		strengthChecks.length = passwordValue.length >= 8 ? true : false;
		strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
		strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
		strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
		// strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);
		let verifiedList = Object.values(strengthChecks).filter((value) => value);
		let strengthCheck = () => {
			if (verifiedList.length == 4) {
				return ekkLanguage.registration.strong;
			} else if (verifiedList.length >= 2) {
				return ekkLanguage.registration.medium;
			} else if (verifiedList.length >= 1) {
				return ekkLanguage.registration.weak;
			}
		};
		// let strength =
		// 	verifiedList.length == 3
		// 		? "Strong"
		// 		: verifiedList.length >= 4
		// 		? "Medium"
		// 		: "Weak";
		// console.log(verifiedList.length / 5);
		setNewPassword_forgotPassword(passwordValue);
		// setProgress(`${verifiedList.length * 100}`);
		setMessage(strengthCheck());
	};

	const getActiveColor = (type) => {
		if (type === ekkLanguage.registration.strong) return "#8BC926";
		if (type === ekkLanguage.registration.medium) return "#FEBD01";
		return "#F94B41";
	};

	const resetPasswordHandler = async (
		newPassword_forgotPassword,
		country,
		phoneNumber,
		countryCode
	) => {
		if (
			newPassword_forgotPassword.length > 3 &&
			confrimNewPassword.length > 3
		) {
			if (
				!(
					newPassword_forgotPassword &&
					confrimNewPassword !== newPassword_forgotPassword
				)
			) {
				let req_body = {
					password: newPassword_forgotPassword,
					country: country,
					phone_number: (countryCode + phoneNumber).trim(),
				};

				try {
					startLoading();
					let response = await Auth.resetPassword(req_body);
					if (response.status === 200 || response.status === 201) {
						// await setIsForgotPassword(false);
						navigate("/");
						// setBlockIndex(authBox_Block.PHONE_NUMBER_BLOCK);
						stopLoading();
						toast.success(ekkLanguage.changePass.successLabel);
					} else {
						toast.error(ekkLanguage.changePass.errorLabel);
					}
				} catch (e) {
					stopLoading();
					console.log(e);
					toast.error(ekkLanguage.changePass.errorLabel);
				}
			} else {
				setLengthError(ekkLanguage.registration.passNotMatch);
			}
		} else {
			setLengthError(ekkLanguage.changePass.lengthErrorLabel);
		}
	};
	return (
		<>
			<LandingNavBar />
			<div className='container w-full h-screen flex items-center justify-center lg:flex-row flex-col overflow-y-scroll'>
				{/* <TextBannerArea ekkLanguage={ekkLanguage} /> */}
				<div className=' bg-[#FFFFFF] lg:p-10 px-5 py-10 rounded-lg shadow-md lg:w-1/2 w-full'>
					<label
						className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
						{ekkLanguage.changePass.passwordLabel}
						<span
							onClick={() => {
								navigate("/");
								// setBlockIndex(authBox_Block.PHONE_NUMBER_BLOCK)
							}}
							className='text-xs text-red cursor-pointer flex items-center'>
							<span className='mr-1'>
								<LeftArrow2 />
							</span>
							{ekkLanguage?.password.backWord}
						</span>
					</label>
					<div className='relative mb-5'>
						<input
							onChange={(event) => {
								handlePassword(event.target.value);
								setNewPassword_forgotPassword(event.target.value);
							}}
							type={!eyeIcon ? "password" : "text"}
							placeholder={ekkLanguage.changePass.passwordPlaceHolder}
							id={"ekk-input"}
							style={{
								paddingLeft: sSize.matches ? 10 : 20,
								height: sSize.matches ? 50 : 60,
								flex: "flex: 1 0 auto",
								borderRadius: 15,
								borderWidth: 1,
								border: "1px solid #00000026",
							}}
							className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
						/>
						<span
							className='absolute right-3 top-[50%]'
							onClick={() => {
								setEyeIcon(!eyeIcon);
							}}
							style={{ transform: "translateY(-50%)" }}>
							{" "}
							{eyeIcon ? <EyeOn /> : <EyeOff />}
						</span>
					</div>
					{newPassword_forgotPassword.length > 3 ? (
						<p
							className='message mt-[-13px]'
							style={{ color: getActiveColor(message) }}>
							{ekkLanguage.registration.passwordLength} {message}
						</p>
					) : null}
					{lengthError && (
						<span className='text-primary mt-1'>{lengthError}</span>
					)}
					<label
						className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
						{ekkLanguage.changePass.confirmPassLabel}
					</label>
					<div className='relative'>
						<input
							onChange={(event) =>
								setConfrimNewPassword(event.target.value)
							}
							type={!eyeIconConfirm ? "password" : "text"}
							placeholder={ekkLanguage.changePass.confirmPassPlaceHolder}
							errorMessage={
								newPassword_forgotPassword !== confrimNewPassword
									? ekkLanguage.changePass.passNotMatchLabel
									: false
							}
							id={"ekk-input"}
							style={{
								paddingLeft: sSize.matches ? 10 : 20,
								height: sSize.matches ? 50 : 60,
								flex: "flex: 1 0 auto",
								borderRadius: 15,
								borderWidth: 1,
								border: "1px solid #00000026",
							}}
							className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
						/>
						<span
							className='absolute right-3 top-[50%]'
							onClick={() => {
								setEyeIconConfirm(!eyeIconConfirm);
							}}
							style={{ transform: "translateY(-50%)" }}>
							{" "}
							{eyeIconConfirm ? <EyeOn /> : <EyeOff />}
						</span>
					</div>

					{error && <span className='text-primary mt-1'>{error}</span>}
					<div className='flex justify-center pt-6'>
						<button
							onClick={() =>
								resetPasswordHandler(
									newPassword_forgotPassword,
									country,
									phoneNo,
									countryCode
								)
							}
							ref={clickBtnRef}
							style={{
								background:
									"linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
							}}
							className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px]`}>
							{ekkLanguage.changePass.btnLabel}
						</button>
					</div>
				</div>
			</div>
			<FooterV2 />
		</>
	);
}
