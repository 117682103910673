import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "pages/Auth/Login";
import ProductDetailsPage from "pages/MobilePage/ProductDetailsPage";
import CreateOrder from "pages/MobilePage/CreateOrder";
import Map from "pages/MobilePage/Map";
import CongratulationPage from "pages/MobilePage/CongratulationPage";
import SelectProductPage from "pages/MobilePage/SelectProductPage";
import OrderList from "pages/MobilePage/OrderList";
import OrderDetailsPage from "pages/MobilePage/OrderDetailsPage";
import BrandDetailsPage from "pages/MobilePage/BrandDetailsPage";
import SuppliersDetailsPage from "pages/MobilePage/SuppliersDetailsPage";
import BusinessNamePage from "pages/MobilePage/BusinessNamePage";
import BusinessTypePage from "pages/MobilePage/BusinessTypePage";
import BusinessLocationPage from "pages/MobilePage/BusinessLocationPage";
import SelectBusiness from "pages/MobilePage/SelectBusiness";
import ProfilePage from "pages/MobilePage/ProfilePage";
import ContactPage from "pages/MobilePage/ContactPage";
import ChangePasswordPage from "pages/MobilePage/ChangePasswordPage";
import ChangePasswordSuccessPage from "pages/MobilePage/ChangePasswordSuccessPage";
import SeeMoreProductPage from "pages/MobilePage/SeeMoreProductPage";
import ProfileVarifyPage from "pages/MobilePage/ProfileVarifyPage";
import ProfileVarifyOtpPage from "pages/MobilePage/ProfileVarifyOtpPage";
import LocationPage from "pages/MobilePage/LocationPage";
import SearchPage from "pages/MobilePage/SearchPage";
import MySuppliersDetailsPage from "pages/MobilePage/MySuppliersDetailsPage";
import ChangeLanguagePage from "pages/MobilePage/ChangeLanguagePage";
import Error from "pages/MobilePage/Error";
import MySupplierPage from "pages/MobilePage/MySupplierPage";
import BusinessProfilePage from "pages/MobilePage/BusinessProfilePage";
import EditBusinessPage from "pages/MobilePage/EditBusinessPage";
import Invoice from "pages/MobilePage/Invoice";
import VBTradLicensePage from "pages/MobilePage/VBTradLicensePage";
import VBNidPage from "pages/MobilePage/VBNid";
import VBPropertyPage from "pages/MobilePage/VBPropertyPage";
import VBRentalPage from "pages/MobilePage/VBRentalPage";
import ReceiptPage from "pages/MobilePage/ReceiptPage";
import BusinessLocationPickerPage from "pages/MobilePage/BusinessLocationPicker";
import Landing from "pages/Landing";
import SingleBlogPage from "pages/Landing/SingleBlogPage";
import BlogPostPage from "pages/Landing/BlogPostPage";
import RefundPolicy from "pages/MobilePage/RefundPolicy";
import PrivacyPolicy from "pages/MobilePage/PrivacyPolicy";
import TermsCondition from "pages/MobilePage/TermsCondition";
import PickLocationPage from "pages/MobilePage/PickLocationPage";
import SellerListPage from "pages/MobilePage/SellerListPage";
import PreOrderSuccessPage from "pages/MobilePage/PreOrderSuccessPage";
import Departments from "pages/MobilePage/Departments";
import NIDVerificationPage from "pages/MobilePage/NIDVerificationPage";
import TradeLicensePage from "pages/MobilePage/TradeLicensePage";
import PropertyTypePage from "pages/MobilePage/PropertyTypePage";
import RentalInfoPage from "pages/MobilePage/RentalInfoPage";
import BusinessSuccess from "components/mobile-components/VerifyBusiness/BusinessSuccess";
import PrivateOutlet from "./PrivateOutlet";

import RecommendedProductPage from "pages/MobilePage/RecommendedProductPage";
import Home from "pages/MobilePage/Home";
import LearningPage from "pages/MobilePage/LearningPage";
import LearningDetailsPage from "pages/MobilePage/LearningDetailsPage";
import NotificationPage from "pages/MobilePage/NotificationPage";
import AutoLogoutPage from "pages/MobilePage/AutoLogoutPage";
import OtpSubmitPage from "pages/MobilePage/OtpSubmitPage";
import VoiceRecorder from "pages/MobilePage/VoiceRecorder";
import AddBankAccountPage from "pages/AddBankAccountPage";
import BankListPage from "pages/BankListPage";
import AddedBankListPage from "pages/MobilePage/AddedBankListPage";
import BlackListedPage from "pages/MobilePage/BlackListedPage";
import IndustryTypePage from "pages/MobilePage/IndustryTypePage";
import PhoneNumberCheck from "pages/LandingPageV2/PhoneNumberCheck";
import PasswordV2 from "pages/LandingPageV2/PasswordV2";
import RegistrationV2 from "pages/LandingPageV2/RegistrationV2";
import TokenV2 from "pages/LandingPageV2/TokenV2";
import ChangePasswordV2 from "pages/LandingPageV2/ChangePasswordV2";
import AccountsPage from "pages/MobilePage/AccountsPage";
import AccountExpensePage from "pages/MobilePage/AccountExpensePage";
import CategoriesPage from "pages/MobilePage/CategoriesPage";
import AccountRevenuePage from "pages/MobilePage/AccountRevenuePage";
import TransactionDetailsPage from "pages/MobilePage/TransactionDetailsPage";
import SupplierListPage from "pages/MobilePage/SupplierListPage";
import AccountDashboardPage from "pages/MobilePage/AccountDashboardPage";
import MyEmployeesListPage from "pages/MobilePage/MyEmployeesListPage";
import EmployeesDetailsPage from "pages/MobilePage/EmployeesDetailsPage";
import CreateEmployeePage from "pages/MobilePage/CreateEmployeePage";
import UpdateEmployeePage from "pages/MobilePage/UpdateEmployeePage";
import AccountRevenueEditPage from "pages/MobilePage/AccountRevenueEditPage";
import AccountExpenseEditPage from "pages/MobilePage/AccountExpenseEditPage";
import BazAiPage from "pages/MobilePage/BazAiPage";
import LoanPage from "pages/MobilePage/LoanPage";
import LoanRequestPage from "pages/MobilePage/LoanRequestPage";
import LoanProductDetailsPage from "pages/MobilePage/LoanProductDetailsPage";
import RevenuePartsPage from "pages/MobilePage/RevenuePartsPage";
import ExpensePartsPage from "pages/MobilePage/ExpensePartsPag";
import PaymentDuePartsPage from "pages/MobilePage/PaymentDuePartsPage";
import ProfitLossPartsPage from "pages/MobilePage/ProfitLossPartsPage";

function Mobile() {
	return (
		<Routes>
			<Route path='/*' element={<PrivateOutlet />}>
				<Route path='home' element={<Home />} />
				<Route
					path='product-details/:id'
					element={<ProductDetailsPage />}
				/>
				<Route
					path='loan-product-details/:id'
					element={<LoanProductDetailsPage />}
				/>
				<Route path='create-order' element={<CreateOrder />} />
				<Route path='location' element={<Map />} />
				<Route path='success-order/:id' element={<CongratulationPage />} />
				<Route path='pre-order-success' element={<PreOrderSuccessPage />} />
				<Route path='select-product' element={<SelectProductPage />} />
				<Route path='order-list' element={<OrderList />} />
				<Route path='accounts' element={<AccountsPage />} />
				<Route path='expense-form' element={<AccountExpensePage />} />
				<Route path='revenue-form' element={<AccountRevenuePage />} />
				<Route
					path='revenue-edit/:id'
					element={<AccountRevenueEditPage />}
				/>
				<Route path='loan' element={<LoanPage />} />
				<Route path='loan-request' element={<LoanRequestPage />} />
				<Route
					path='expense-edit/:id'
					element={<AccountExpenseEditPage />}
				/>
				<Route
					path='transaction-details/:id'
					element={<TransactionDetailsPage />}
				/>
				<Route path='categories/:type/:id' element={<CategoriesPage />} />
				<Route path='supplier-list/:type' element={<SupplierListPage />} />
				<Route
					path='accounts-dashboard'
					element={<AccountDashboardPage />}
				/>
				<Route path='order-details/:id' element={<OrderDetailsPage />} />
				<Route path='brand-details/:id' element={<BrandDetailsPage />} />
				<Route
					path='supplier-details/:id'
					element={<SuppliersDetailsPage />}
				/>
				<Route
					path='mysupplier-details/:id'
					element={<MySuppliersDetailsPage />}
				/>
				<Route path='business-name' element={<BusinessNamePage />} />
				<Route path='industry-type' element={<IndustryTypePage />} />
				<Route path='select-business' element={<SelectBusiness />} />
				<Route path='business-type' element={<BusinessTypePage />} />
				{/* <Route
					path='employes-list/:category/:id'
					element={<EmployeesListPage />}
				/> */}
				<Route path='employees-list' element={<MyEmployeesListPage />} />
				<Route
					path='employee-details/:id'
					element={<EmployeesDetailsPage />}
				/>
				<Route path='create-employee' element={<CreateEmployeePage />} />
				<Route
					path='update-employee/:id'
					element={<UpdateEmployeePage />}
				/>
				<Route
					path='business-location'
					element={<BusinessLocationPage />}
				/>
				<Route path='profile' element={<ProfilePage />} />
				<Route path='contact' element={<ContactPage />} />
				<Route path='notifications' element={<NotificationPage />} />
				<Route path='change-password' element={<ChangePasswordPage />} />
				<Route
					path='changed-password-success'
					element={<ChangePasswordSuccessPage />}
				/>
				<Route path='products/:category' element={<SeeMoreProductPage />} />
				<Route path='seller' element={<SellerListPage />} />
				<Route path='otp-send' element={<ProfileVarifyPage />} />
				<Route path='otp-submit' element={<ProfileVarifyOtpPage />} />
				<Route path='change-language' element={<ChangeLanguagePage />} />
				<Route path='location-enabled' element={<LocationPage />} />
				<Route path='search-page/:tarm' element={<SearchPage />} />
				<Route
					path='department/:id/:department'
					element={<Departments />}
				/>
				<Route path='mysupplier' element={<MySupplierPage />} />
				<Route
					path='business-profile-settings'
					element={<BusinessProfilePage />}
				/>
				<Route path='pick-location/:id' element={<PickLocationPage />} />
				<Route path='business-edit' element={<EditBusinessPage />} />
				<Route path='vbtrade-license' element={<VBTradLicensePage />} />
				<Route path='vbnid' element={<VBNidPage />} />
				<Route path='vbproperty' element={<VBPropertyPage />} />
				<Route path='vbrental-document' element={<VBRentalPage />} />
				<Route path='invoice/:id' element={<Invoice />} />
				<Route path='receipt/:id' element={<ReceiptPage />} />
				<Route
					path='search-location'
					element={<BusinessLocationPickerPage />}
				/>
				<Route path='nid-verification' element={<NIDVerificationPage />} />
				<Route
					path='trade-license-verification'
					element={<TradeLicensePage />}
				/>
				<Route path='property-type' element={<PropertyTypePage />} />
				<Route path='rental-info' element={<RentalInfoPage />} />
				<Route path='business-success' element={<BusinessSuccess />} />
				<Route
					path='recommended-product'
					element={<RecommendedProductPage />}
				/>
				<Route path='products' element={<RecommendedProductPage />} />
				<Route path='learning' element={<LearningPage />} />
				<Route
					path='learning-details/:id'
					element={<LearningDetailsPage />}
				/>
				<Route path='business-delete-otp' element={<OtpSubmitPage />} />
				<Route path='voice-recorder' element={<VoiceRecorder />} />
				<Route path='baz-ai' element={<BazAiPage />} />
				{/* <Route
					path='voice-search/product-detail/:id/:type'
					element={<BrandProductDetailsPage />}
				/> */}
				<Route path='add-bank-account' element={<AddBankAccountPage />} />
				<Route path='bank-list' element={<BankListPage />} />
				<Route path='added-bank-list' element={<AddedBankListPage />} />
				<Route path='added-bank-list' element={<AddedBankListPage />} />
				<Route path='revenue-parts' element={<RevenuePartsPage />} />
				<Route path='expense-parts' element={<ExpensePartsPage />} />
				<Route path='due-payment-parts' element={<PaymentDuePartsPage />} />
				<Route path='profit-loss-parts' element={<ProfitLossPartsPage />} />

				<Route path='*' element={<Error />} />
			</Route>
			<Route path='/login' element={<Login />} />
			<Route path='/' element={<PhoneNumberCheck />} />
			<Route path='/password' element={<PasswordV2 />} />
			<Route path='/registration' element={<RegistrationV2 />} />
			<Route path='/submit-token' element={<TokenV2 />} />
			<Route path='/change-password' element={<ChangePasswordV2 />} />
			<Route path='/post/en/:id' element={<SingleBlogPage />} />
			<Route path='/post/en' element={<BlogPostPage />} />
			<Route path='/refund-policy' element={<RefundPolicy />} />
			<Route path='/privacy-policy' element={<PrivacyPolicy />} />
			<Route path='/terms-conditions' element={<TermsCondition />} />
			<Route path='*' element={<Error />} />
			<Route path='/auto-log-out' element={<AutoLogoutPage />} />
			<Route path='/black-listed' element={<BlackListedPage />} />
		</Routes>
	);
}

export default Mobile;
