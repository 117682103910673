import { getLanguage } from "config/language";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

export default function TopMenu() {
	let navigate = useNavigate();
	const appLanguage = getLanguage();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	let menuList = [
		// {
		// 	id: 1,
		// 	image: "https://ekkfilesdbt.blob.core.windows.net/userssblobs/750c3dba-5138-4955-8c8c-d5e27c09032dicon-accounting50x50.png",
		// 	title: ekkLanguage.menu.accounting,
		// 	link: "/accounts",
		// },
		{
			id: 2,
			image: "/assets/images/procurement.png",
			title: ekkLanguage.menu.procurement,
			link: "/order-list",
		},
		{
			id: 3,
			image: "/assets/images/products.png",
			title: ekkLanguage.menu.products,
			link: "/products",
		},
		{
			id: 4,
			image: "/assets/images/loan.png",
			title: ekkLanguage.menu.loan,
			link: "/loan",
		},
		{
			id: 5,
			image: "/assets/images/menu/learning-c.png",
			title: ekkLanguage.navFooter.learning,
			link: "/learning",
		},
	];

	return (
		<div className='product-slider'>
			<Swiper
				spaceBetween={0}
				slidesPerView={2.5}
				grabCursor={true}
				breakpoints={{
					260: {
						slidesPerView: 2,
					},
					320: {
						slidesPerView: 3,
					},
					360: {
						slidesPerView: 4.5,
					},
					575: {
						slidesPerView: 4.5,
					},
					768: {
						slidesPerView: 5.5,
					},
				}}>
				{menuList?.map((el, i) => {
					return (
						<>
							{" "}
							<SwiperSlide
								key={i}
								// onClick={() => {
								// 	if (el?.title === "Accounting") {
								// 		navigate("/accounts");
								// 	} else {
								// 		navigate(
								// 			`/department/${el.id}/${
								// 				appLanguage == "bn"
								// 					? el?.title_in_native
								// 					: el?.title
								// 			}`
								// 		);
								// 	}
								// }}
								onClick={() => navigate(el.link)}>
								<div className='text-center'>
									<div
										className='rounded-full h-[50px] w-[50px] flex m-auto'
										style={{
											background: "rgba(0, 178, 36, 0.15)",
											boxShadow:
												"0px 5px 20px 0px rgba(0, 0, 0, 0.05)",
										}}>
										<img
											src={el?.image}
											className='m-auto'
											alt='icon'
										/>
									</div>
									<p className='text-12 font-semibold mt-[7px] text-262626-1000'>
										{el?.title}
									</p>
								</div>
							</SwiperSlide>
						</>
					);
				})}
			</Swiper>
		</div>
	);
}
